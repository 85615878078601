import React, { Fragment, FunctionComponent } from 'react';
import { Portal, Realm } from '../../api/api-constants';
import { QuickLinksSection } from './QuickLinksSection';
import { AnnouncementSection } from './AnnouncementSection';
import { QuickStartSection } from './QuickStartSection';
import { RealmDisplaySection } from './RealmDisplaySection';
import { adminQuickLinkCardItems, basicQuickLinkCardItems } from '../../constants/display/quicklinkcardcontent';


export interface HomePageProps {
    realm: Realm,
    portal: Portal,
    testId: string,
    setNotification?: Function,
}

export const HomePage: FunctionComponent<HomePageProps> = ({ realm, portal, testId }) => {
    const cardItems = portal === Portal.ADMIN ? adminQuickLinkCardItems : basicQuickLinkCardItems;
    return( <Fragment>
        <QuickStartSection testId={testId} />
        <RealmDisplaySection testId={testId} realm={realm} />
        <QuickLinksSection testId={testId} cardItems={cardItems}/>
        <AnnouncementSection testId={testId} />
    </Fragment>
    );
};
