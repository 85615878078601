import { FlashbarMessageDefinition } from '../constants/display/flashbar-messages';

const UNAUTHORIZED_ERROR_CODE = 403;
const DOES_NOT_EXIST_CODE = 404;
const INVALID_ARGUMENTS_CODE = 400;

export const handleErrorResponse = (error: any, setNotification: Function, flashbarMessage: FlashbarMessageDefinition) => {
    let errorMessage: string;

    if (!error || !error.response) {
        errorMessage = 'There was an unexpected error in the backend service. Please contact the ReSES team if the issue persists.';
    } else {
        switch (error.response.status) {
            case INVALID_ARGUMENTS_CODE:
                errorMessage = error.response.data.message;
                break;
            case UNAUTHORIZED_ERROR_CODE:
                errorMessage = 'You are not authorized to access this data. Please contact the Limestone team to onboard to our platform.';
                break;
            case DOES_NOT_EXIST_CODE:
                errorMessage = 'You are trying to access an API which does not exist, please visit our onboarding wiki to know more about the available features.';
                break;
            default:
                errorMessage = 'There was an error in our backend service please try again after sometime. If the issue persists please cut us a SIM and we will look into it.';
        }
    }
    
    setNotification(flashbarMessage, errorMessage);
};