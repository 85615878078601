export const getEmptyCount = (originalValues: string[]): number => {
    return originalValues.filter((value) => value.trim().length === 0).length;
};

export const getDuplicates = (originalValues: string[]): Set<string> => {
    const nonEmptyValues = originalValues.filter((value) => value.trim().length > 0).map((value) => value.trim());

    const counter = nonEmptyValues.reduce((accumulator: any, value: any) => {
        accumulator[value] = ++accumulator[value] || 0;
        return accumulator;
    }, {});
      
    return new Set(nonEmptyValues.filter((value: string) => counter[value]));
};

export const getUniqueValues = (originalValues: string[]): string[] => {
    const nonEmptyValues = originalValues.filter((value) => value.trim().length > 0).map((value) => value.trim());

    const counter = nonEmptyValues.reduce((accumulator: any, value: any) => {
        accumulator[value] = ++accumulator[value] || 0;
        return accumulator;
    }, {});
      
    return nonEmptyValues.filter((value: any) => counter[value] === 0);
};