import { EndpointMap } from '../api-constants';

export const CDC_API_ENDPOINTS: EndpointMap = {
    // TODO: Update Endpoints once all stages in CDC are deployed.
    beta_na: 'https://ihfjcgyl73.execute-api.us-east-1.amazonaws.com/prod-us-east-1',
    gamma_na: 'https://ihfjcgyl73.execute-api.us-east-1.amazonaws.com/prod-us-east-1',
    prod_na: 'https://ihfjcgyl73.execute-api.us-east-1.amazonaws.com/prod-us-east-1',
    beta_eu: 'https://ihfjcgyl73.execute-api.us-east-1.amazonaws.com/prod-us-east-1',
    prod_eu: 'https://ihfjcgyl73.execute-api.us-east-1.amazonaws.com/prod-us-east-1',
    dev_na: 'https://ihfjcgyl73.execute-api.us-east-1.amazonaws.com/prod-us-east-1',
    dev_eu: 'https://ihfjcgyl73.execute-api.us-east-1.amazonaws.com/prod-us-east-1'
};