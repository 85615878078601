import { MetadataAttribute } from '../../enums/CommonTypes';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { SelectField, SelectFieldConfig } from '../fields/SelectField';
import { AttributeLabels } from '../../constants/display/string-constants';

export class ProductFamily extends SelectField {
    protected displayConfig: SelectFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true };

        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.PRODUCT_FAMILY,
            editable: false,
            touched: false,
            placeholder: 'Select from the drop-down',
            filteringType: 'auto',
            hintText: 'Required *',
            errorText,
            onChange: (event) => this.onChangeEvent(event, MetadataAttribute.PRODUCT_FAMILY)
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        this.displayConfig.options = [
            { label: 'Consumables', id: 'Consumables' },
            { label: 'Hardlines', id: 'Hardlines' },
            { label: 'Softlines', id: 'Softlines' },
            { label: 'Media', id: 'Media' }
        ];

        this.forceUpdate();
    }

    setValueFromPayload = async(productFamily: string) => {
        this.setValue({ id: productFamily, label: productFamily });
    }
}