import { Select } from '@amzn/awsui-components-react/polaris';
import * as LambdaModel from '../../api/experiment-service/lambda-model-types';
import { MetadataAttribute } from '../../enums/CommonTypes';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { MultiSelectField, MultiSelectFieldConfig } from '../fields/MultiSelectField';
import { AttributeLabels } from '../../constants/display/string-constants';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';

export class SecondaryBusinessGroups extends MultiSelectField {
    private experimentServiceAPI: LemsApiHandler;
    protected displayConfig: MultiSelectFieldConfig;
    protected staticConfig: string;
    protected isLoading: boolean;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const disabled = this.props.removeOption ? false : true;
        const hintText = this.props.removeOption ? undefined : 'Please Fill out the Primary Business Group first';
        this.staticConfig = '';
        this.isLoading = true;

        this.displayConfig = {
            label: AttributeLabels.SECONDARY_BUSINESS_GROUPS,
            editable: false,
            touched: false,
            placeholder: 'Select from the drop-down',
            checkboxes: true,
            filteringType: 'auto',
            disabled,
            hintText,
            onChange: (event) => this.onChangeEvent(event, MetadataAttribute.SECONDARY_BUSINESS_GROUPS)
        };

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            validity: true
        };
        this.experimentServiceAPI = new ApiHandler(this.props.realm!);

    }

    componentDidMount = async() => {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
        
        this.experimentServiceAPI.getStaticConfigurationData(this.props.realm!.toString())
            .then((response: string) => { 
                this.staticConfig = response;
                const parseConfiguration = JSON.parse(this.staticConfig);

                const productFamiliesObject = parseConfiguration[Object.keys(parseConfiguration)[0]];
        
                let allItems: any[] = [];
                let arrayOfOptions: string[] = [];
        
                Object.keys(productFamiliesObject).forEach(function(key) {
                    const currentObjectItem = productFamiliesObject[key].items;
        
                    Object.keys(currentObjectItem).forEach(function(subKey) {
                        arrayOfOptions.push(currentObjectItem[subKey]);
                    });
        
                    let displayConfigItem = {
                        label: key,
                        options: arrayOfOptions.map((groupName: string) => { return { label: groupName, id: groupName }; })
                    };
        
                    allItems.push(displayConfigItem);
                });
                this.displayConfig.options = allItems;
        
                this.forceUpdate();
            })
            .catch((error: any) => console.log(error))
            .finally(() => {this.isLoading = false;});
    }

    componentDidUpdate(prevProps: ExperimentAttributeProps) {
        if (this.props.removeOption !== prevProps.removeOption) {
            this.displayConfig.disabled = this.props.removeOption ? false : true;
            this.displayConfig.hintText = this.props.removeOption ? undefined : 'Please Fill out the Primary Business Group first';

            let parseConfiguration = JSON.parse(this.staticConfig);

            const productFamiliesObject = parseConfiguration[Object.keys(parseConfiguration)[0]];

            let allItems: any[] = [];     
                   
            for (var key in productFamiliesObject) {
                let arrayOfOptions: string[] = [];
                const currentObjectItem = productFamiliesObject[key].items;

                Object.keys(currentObjectItem).forEach(function(subKey) {
                    arrayOfOptions.push(currentObjectItem[subKey]);
                });

                let displayConfigItem = {
                    label: key,
                    options: arrayOfOptions.map((groupName: string) => { return { label: groupName, id: groupName }; }).filter((option) => option.label !== this.props.removeOption)
                };

                allItems.push(displayConfigItem);
            }
            
            this.displayConfig.options = allItems;
            this.forceUpdate();
        }
    }

    getPayloadValue = (): LambdaModel.BusinessGroupDto[] => {
        const businessGroups: LambdaModel.BusinessGroupDto[] = [];
        this.getSelectedOptions().forEach((selectedOption) => {
            businessGroups.push({
                businessGroup: selectedOption.label,
                primary: false
            });
        });

        return businessGroups;
    };

    setValueFromPayload = async(businessGroups: LambdaModel.BusinessGroupDto[]) => {
        const selectedOptions: Select.Option[] = [];
        businessGroups.forEach((businessGroupDto) => {
            selectedOptions.push({
                id: businessGroupDto.businessGroup,
                label: businessGroupDto.businessGroup
            });
        });

        this.setValue(selectedOptions);
    }
}