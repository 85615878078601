import { Select } from '@amzn/awsui-components-react/polaris';

export const NA_MARKETPLACES: Array<Select.IOption | Select.Option | Select.OptionsGroup> | null = [
    { id: 'US', label: 'US' }
];

export const EU_MARKETPLACES: Array<Select.IOption | Select.Option | Select.OptionsGroup> | null = [
    { id: 'DE', label: 'DE' },
    { id: 'IT', label: 'IT' },
    { id: 'FR', label: 'FR' },
    { id: 'ES', label: 'ES' },
    { id: 'UK', label: 'UK' }
];