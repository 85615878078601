import React, { Component } from 'react';
import { Button, Spinner, Select, Flashbar } from '@amzn/awsui-components-react/polaris';
import GetAppIcon from '@material-ui/icons/GetApp';
import UserContext from '../../context/UserContext';
import { DataTable, DataTableDefinition } from '../../common/DataTable';
import { StepDetailsQueryParams } from '../../api/api-constants';
import * as UrlUtils from '../../utils/url-utils';
import * as FormUtils from '../../utils/form-utils';
import * as DateUtils from '../../utils/date-utils';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import { CdcApiHandler } from '../../api/data-collection/handler/cdc-api-handler';
import CdcApiHandlerImpl from '../../api/data-collection/handler/cdc-api-handler-impl';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import * as NOTIFICATION_MESSAGES from '../../constants/display/flashbar-messages';
import { LimestoneExperiment } from '../../interfaces/LimestoneExperiment';
import { PageProps } from '../../interfaces/IProps';
import * as TransactionalMetricsAdaptor from '../../api/data-collection/adaptors/transactional-metrics-adaptor';
import { GetTransactionalMetricsResponse, GetDownstreamMetricsResponse } from '../../api/data-collection/lambda-model-types';
import { TransactionalMetricColumnMap } from '../../interfaces/TransactionalMetrics';
import { DataTableNames } from '../../constants/display/string-constants';

export interface TransactionalMetricsState {
    experimentId: string;
    experimentIntegerId: number;
    experiment: LimestoneExperiment;
    selectedDay: string;
    availableDays: string[];
    transactionalMetricsTableDefinitions: DataTableDefinition[];
    downStreamMetricsTableDefinitions: DataTableDefinition[];
    totalTreatmentCustomers: number;
    totalControlCustomers: number;
    transactionalMetricsLoading: boolean;
    transactionalMetricsDisabled: boolean;
    downstreamMetricsLoading: boolean;
    downstreamMetricsDisabled: boolean;
    showSpinner: boolean;
}

export class TransactionalMetrics extends Component<PageProps, TransactionalMetricsState> {
    static contextType = UserContext;

    /** Experiment Service handler instance which provides api to get the experiment data from the backend */
    public experimentServiceAPI: LemsApiHandler;

    /** Data Collection Service handler instance which provides api to get the metrics data from the backend */
    public dataCollectionAPI: CdcApiHandler;

    // Custom error code in CDC: https://tiny.amazon.com/y2cgql49/codeamazpackReSEblobf064mode
    private readonly CDC_DATA_NOT_READY_CODE = 550;

    private readonly DEFAULT_DOWNSTREAM_METRICS_COLUMNS: string[] = [
        TransactionalMetricColumnMap.METRIC_DIMENSION,
        TransactionalMetricColumnMap.ABSOLUTE_LIFT,
        TransactionalMetricColumnMap.LIFT_PER_USER,
        TransactionalMetricColumnMap.P_VALUE
    ];

    constructor(props: PageProps) {
        super(props);
        this.state = {
            experimentId: '',
            experimentIntegerId: -1,
            experiment: FormUtils.createEmptyLimestoneExperiment(),
            selectedDay: '',
            availableDays: [],
            transactionalMetricsTableDefinitions: [],
            downStreamMetricsTableDefinitions: [{ 
                tableName: DataTableNames.DOWNSTREAM_METRICS,
                columnNames: this.DEFAULT_DOWNSTREAM_METRICS_COLUMNS,
                rows: []
            }],
            totalTreatmentCustomers: 0,
            totalControlCustomers: 0,
            transactionalMetricsLoading: false,
            transactionalMetricsDisabled: false,
            downstreamMetricsLoading: false,
            downstreamMetricsDisabled: false,
            showSpinner: false
        };

        this.experimentServiceAPI = new LemsApiHandlerImpl(props.realm);
        this.dataCollectionAPI = new CdcApiHandlerImpl(props.realm);
    }

    /* istanbul ignore next */
    componentDidMount = async() => {
        const { experimentId, experimentIntegerId, date }: StepDetailsQueryParams = UrlUtils.parseStepDetailsFromUrl(new URL(window.location.href));
        this.setState({ showSpinner: true });

        const currentDate = new Date();
        const selectedDay = date ? this.getLastSunday(DateUtils.constructDate(date)) : this.getLastSunday(currentDate);

        await this.experimentServiceAPI.readExperiment(experimentId, experimentIntegerId)
            .then((experiment: LimestoneExperiment) => {
                const startDate = DateUtils.constructDate(experiment.metadata.startDate.displayValue);
                this.setState({ experimentId, experimentIntegerId, experiment, selectedDay: selectedDay.toDateString() });

                if (startDate > selectedDay) {
                    return;
                }

                const allSundays: string[] = [];
                while (startDate < currentDate) {
                    if (startDate.getDay() === 0) { 
                        allSundays.push(startDate.toDateString());
                    }
                    startDate.setDate(startDate.getDate() + 1);
                }
    
                this.setState({ availableDays: allSundays.reverse() });
            })
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.readExperiment.FAIL!))
            .finally(() => this.setState({ showSpinner: false }));

        await this.fetchTransactionalMetrics();
        await this.fetchDownstreamMetrics();
    }

    fetchTransactionalMetrics = async() => {
        this.setState({ showSpinner: true });
        await this.dataCollectionAPI.getTransactionalMetrics(this.state.experimentId, DateUtils.constructDateString(new Date(this.state.selectedDay)))
            .then((response: GetTransactionalMetricsResponse) => {
                this.setState({
                    totalTreatmentCustomers: response.totalTreatmentCustomers,
                    totalControlCustomers: response.totalControlCustomers,
                    transactionalMetricsTableDefinitions: TransactionalMetricsAdaptor.convertMetricsTableToDataTable(response.table)
                });
            })
            .catch((error: any) => {
                if (!(error.response && error.response.status === this.CDC_DATA_NOT_READY_CODE)) {
                    this.setState({ transactionalMetricsTableDefinitions: [] });
                    handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getTransactionalMetrics.FAIL!);
                }
            })
            .finally(() => this.setState({ showSpinner: false }));
    }

    fetchDownstreamMetrics = async() => {
        this.setState({ showSpinner: true });
        await this.dataCollectionAPI.getDownstreamMetrics(this.state.experimentId)
            .then((response: GetDownstreamMetricsResponse) => {
                this.setState({
                    downStreamMetricsTableDefinitions: TransactionalMetricsAdaptor.convertMetricsTableToDataTable(response.table)
                });
            })
            .catch((error: any) => {
                if (!(error.response && error.response.status === this.CDC_DATA_NOT_READY_CODE)) {
                    handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getDownstreamMetrics.FAIL!);
                }
            })
            .finally(() => this.setState({ showSpinner: false }));
    }

    onDateChange = async(event: CustomEvent<Select.ChangeDetail>) => {
        this.setState({ selectedDay: event.detail.selectedOption.label });
        await this.fetchTransactionalMetrics();
    }

    getLastSunday = (date: Date): Date => {
        var resultDate = new Date(date.getTime());
        resultDate.setDate((date.getDate() - (date.getDay()) % 7));
        return resultDate;
    }

    render() {
        const availableDays: Select.Option[] = this.state.availableDays.map((day) => { return { id: day, label: day };});
        const selectedDay = { id: this.state.selectedDay, label: this.state.selectedDay };

        const actionStripe = (
            <>
                <div className="awsui-util-mb-m awsui-util-mt-xs" style={{ margin: 0 }}>
                    <div className="awsui-util-action-stripe-large">
                        <div className="awsui-util-action-stripe-title">
                            <h1>{`${this.state.experiment.metadata.title.displayValue} (ID: ${this.state.experimentIntegerId})`}</h1>
                        </div>
                        <div className="awsui-util-action-stripe-group">
                            <Select
                                data-testid={'data-change-dropdown'}
                                options={availableDays}
                                selectedOption={selectedDay}
                                onChange={this.onDateChange}
                            />
                            <Button
                                data-testid={'transactional-metrics-download-button'}
                                loading={this.state.transactionalMetricsLoading}
                                disabled={this.state.transactionalMetricsDisabled}
                            ><GetAppIcon />Transactional Metrics</Button>
                            <Button
                                data-testid={'downstream-metrics-download-button'}
                                loading={this.state.downstreamMetricsLoading}
                                disabled={this.state.downstreamMetricsDisabled}
                            ><GetAppIcon />Downstream Metrics</Button>
                        </div>
                    </div>
                    <div data-testid={'transactional-metrics-summary'}>
                        <h5 data-testid='treatment-customers-header'><strong>{'Total Treatment Customers: '}</strong>{this.state.totalControlCustomers}</h5>
                        <h5 data-testid='control-customers-header'><strong>{'Total Control Customers: '}</strong>{this.state.totalControlCustomers}</h5>
                    </div>
                </div>
            </>
        );

        let content: JSX.Element;
        if (this.state.showSpinner) {
            content = (<Spinner data-testid={'metrics-loading-spinner'} size='large' />);
        } else if (this.state.transactionalMetricsTableDefinitions.length === 0) {
            content = (
                <>
                    <Flashbar data-testid={'metrics-empty-flashbar'} items={[{ 
                        header: `Metrics are not ready for this experiment: ${this.state.experiment.metadata.title.displayValue} (ID: ${this.state.experimentIntegerId})`,
                        content: `Please select a different date: Metrics are generated every Sunday following the start date of the experiment. The first iteration of metrics collection will begin on ${this.state.availableDays[0]}`,
                        type: 'warning' 
                    }]}/>
                    {actionStripe}
                </>
            );
        } else {
            content = (
                <>
                    {actionStripe}
                    <div style={{ margin: '10px 0' }}></div>
                    {this.state.transactionalMetricsTableDefinitions.map((tableDefinition: DataTableDefinition) => (
                        <DataTable key={tableDefinition.tableName} tableDefinition={tableDefinition} firstColumnHeader={true} expandableSection={true}/>
                    ))}
                    {this.state.downStreamMetricsTableDefinitions.map((tableDefinition: DataTableDefinition) => (
                        <DataTable key={'Downstream Metrics'} tableDefinition={tableDefinition} firstColumnHeader={true} expandableSection={true}/>
                    ))}
                </>
            );
        }

        return (
            <div className="awsui-util-container" style={{ padding: 5 }}>
                {content}
            </div>
        ); 
    }
}
