import { MetadataAttribute } from '../../enums/CommonTypes';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { TagField, TagFieldConfig } from '../fields/TagField';
import * as LambdaModel from '../../api/experiment-service/lambda-model-types';
import { TokenGroup } from '@amzn/awsui-components-react/polaris';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { Realm } from '../../api/api-constants';
import { AttributeLabels } from '../../constants/display/string-constants';

export class Tags extends TagField {
    protected displayConfig: TagFieldConfig;
    private experimentServiceAPI: LemsApiHandler;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const realm = props.realm ? props.realm : Realm.NA;
        this.experimentServiceAPI = new ApiHandler(realm);

        this.displayConfig = {
            label: AttributeLabels.TAGS,
            editable: false,
            value: [],
            inputProps: {
                className: 'react-tagsinput-input',
                placeholder: 'Ex: Demo'
            },
            hintText: 'Press Tab or Enter to add multiple tags',
            touched: false,
            onChange: (event: any) => this.onChangeEvent(event, MetadataAttribute.TAGS)
        };

        this.addItemFieldConfig = {
            editable: false,
            value: '',
            type: 'text',
            placeholder: 'Add a tag',
            touched: false,
            onChange: (event) => this.onAddItemChangeEvent(event),
            onKeydown: (event) => this.onAddToken(event)
        };

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            validity: true,
            displayTokens: []
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
            const displayTokens: TokenGroup.Item[] = this.props.initialValue.map((tagDto: LambdaModel.TagDto) => {
                return {
                    label: tagDto.tag,
                    dismissLabel: `Remove ${tagDto.tag}` 
                };
            });
    
            this.setState({ displayTokens });
        }
    }

    getPayloadValue = (): LambdaModel.TagDto[] => {
        const tags: LambdaModel.TagDto[] = [];
        this.getTags().forEach((tag) => {
            if (tag.trim()) {
                tags.push({
                    tag: tag.trim()
                });
            }
        });

        return tags;
    };

    setValueFromPayload = async(tags: LambdaModel.TagDto[]) => {
        const selectedTags: string[] = [];
        tags.forEach((tagDto) => {
            selectedTags.push(tagDto.tag);
        });

        this.setValue(selectedTags);
    }

    addItemToBackend = async(newValue: string): Promise<boolean> => {
        return await this.experimentServiceAPI.addTag(this.props.experimentId!, { tag: newValue });
    }

    removeItemFromBackend = async(value: string): Promise<boolean> => {
        return await this.experimentServiceAPI.removeTag(this.props.experimentId!, { tag: value });
    }
}