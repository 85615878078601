import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';
import { Realm, Stage } from './api-constants';
import * as UrlUtils from '../utils/url-utils';
import { EndpointMap } from './api-constants';

export class ApiHandler {
    private static readonly API_MAP_DELIMITER = '_';

    protected instance: AxiosInstance;
    protected stage: Stage;
    protected config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-amz-json-1.1'
        }
    };

    public constructor(realm: Realm, endpoints: EndpointMap) {
        const url: URL = new URL(window.location.href);
        this.stage = UrlUtils.parseStageFromUrl(url);
        const baseURL = endpoints[this.stage + ApiHandler.API_MAP_DELIMITER + realm];
        this.instance = axios.create({ baseURL });
    }

    public getInstance = () => this.instance;

    public getConfig = () => this.config

    protected authenticate = async() => {
        const session = await Auth.currentSession();
        this.config.headers['Authorization'] = session.getIdToken().getJwtToken();
    }

    protected sendHttpPutRequest = async(url: string, payload: any) => {
        await this.authenticate();

        const response = await this.instance.put(url, payload, this.getConfig());
        if (!response) {
            console.error(`No response from ${url}`);
        }

        return response.data;
    }

    protected sendHttpGetRequest = async(url: string, requestConfig: AxiosRequestConfig) => {
        await this.authenticate();

        const response = await this.instance.get(url, requestConfig);
        if (!response) {
            console.error(`No response from ${url}`);
        }

        return response.data;
    }

    protected sendHttpPostRequest = async(url: string, payload: any) => {
        await this.authenticate();

        const response = await this.instance.post(url, payload, this.getConfig());
        if (!response) {
            console.error(`No response from ${url}`);
        }

        return response.data;
    }
}
