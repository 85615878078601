import React, { Component } from 'react';
import { Button, FormField, TokenGroup } from '@amzn/awsui-components-react/polaris';
import { IValidationRules } from '../validation/validation-rules';
import { Realm } from '../api/api-constants';
import { DisplayMode } from '../interfaces/FormAttribute';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

/**
 * The Configuration which will be used for the Polaris Form Fields.
 * Reference: https://v2-1.polaris.a2z.com/components/awsui-form-field/?example=default&tabId=ApiHandler
 * @param label the label for the form field.
 * @param touched flag to represent whether the field is edited before or not.
 * @param editable flag to represent whether the field is editable after the create stage.
 * @param errorText error text to be displayed if the field is invalid.
 * @param hintText text to be displayed below the form to describe any requirement to the user.
 */
export interface ExperimentAttributeConfig {
    label?: string | null;
    touched: boolean;
    editable: boolean;
    errorText?: string | null;
    hintText?: string | undefined;
}

/**
 * The Props for the Experiment Attribute class.
 * @param initialValue initial value to be displayed in the field.
 * @param displayMode display mode for the form to be set initially.
 * @param isAuthorizedToEdit flag to represent whether the user is authorized to edit the field.
 * @param experimentId experimentId associated to the attribute.
 * @param marketplaceId marketplaceId associated to the experiment.
 * @param experimentStatus status of the experiment associated to the experiment.
 * @param removeOption option which needs to removed from a select field.
 * @param startDate start date of the experiment.
 * @param endDate end date of the experiment.
 * @param updateFormState Function that will update the form state of the parent class.
 */
export interface ExperimentAttributeProps {
    initialValue?: any;
    displayMode?: DisplayMode;
    realm?: Realm;
    experimentId?: string;
    marketplaceId?: string;
    experimentStatus?: string;
    removeOption?: string;
    startDate?: string;
    endDate?: string;
    isAuthorizedToEdit?: boolean;
    updateFormState?: (_fieldId: string, _payloadValue: any, displayValue: string, _validity: boolean) => void;
}

/**
 * State of the Experiment Attribute Component.
 * @param displayValue format of the value as needed by the frontend display
 * @param displayMode display mode for the form (Edit / View / Create) 
 * @param validity flag to represent whether the field is in valid state or not
 * @param editInProgress flag to represent whether an edit operation is in progress or not
 * @param secondaryValue value of any secondary component associated with the attribute
 * @param displayTokens array of tokens to be displayed for tag type attributes
 */
export interface ExperimentAttributeState {
    displayValue: string;
    displayMode: DisplayMode,
    validity: boolean;
    editInProgress?: boolean;
    secondaryValue?: string;
    displayTokens?: TokenGroup.Item[];
}

/**
 * An abstract class for any attribute associated to a Limestone Experiment entity.
 */
export abstract class ExperimentAttribute extends Component<ExperimentAttributeProps, ExperimentAttributeState> {
    protected validationRules: IValidationRules = {};
    protected displayConfig!: ExperimentAttributeConfig;

    /**
     * Function that is triggered whenever the value of the form field is changed. It will be responsible for
     * changing any state variables of this component as well as form state of the parent component.
     * @param event event that has triggered the function
     * @param fieldId id of the field in the parent form state.
     */
    onChangeEvent = async(event: CustomEvent, fieldId: string): Promise<ExperimentAttribute> => {
        const updatedField = Object.create(this);
        const newValue = this.parseValueFromEvent(event);
        await updatedField.setValue(newValue);
        if (this.props.updateFormState) {
            this.props.updateFormState(fieldId, this.getPayloadValue(), this.getDisplayValue(), this.getValidity());
        }
        return updatedField;
    };

    /**
     * Function that parses value from the change event. This function will change depending on the type of JSX field
     * used for this attribute.
     * @param event change event which contains the updated value.
     */
    parseValueFromEvent = (event: CustomEvent<any>): any => event.detail.value;

    /** Function to get the display value of the component */
    getDisplayValue = (): string => this.state.displayValue;

    /** Function to get the validity of the component */
    getValidity = () => this.state.validity;

    /**
     * Function to update the appropriate component members in addition to setting the state. Any validations to be done
     * before setting the value have to performed here. The display configuration for any JSX component to be used as well
     * as the error configuration of the form is to be set here.
     * @param newValue updated value of the attribute
     */
    setValue = async(newValue: any): Promise<void> => await new Promise((resolve) => this.setState({ displayValue: newValue }, () => resolve(newValue)));

    /**
     * Function which converts the value of the attribute as needed by the backend. This function acts as an adaptor and
     * performs any model transformations so that it is appropriate format as needed by the backend API.
     */
    getPayloadValue = (): any => this.state.displayValue;

    /**
     * Function which sets the members of the component from the backend value. This function performs any transformations
     * needed before calling the setValue function.
     * @param newValue updated value of the attribute.
     */
    setValueFromPayload = (newValue: any): Promise<void> => this.setValue(newValue);

    /**
     * Function which validates the value of the field based on the validation rules given to it.
     * @param value value to be validated
     * @param validationRules rules to be validated on
     */
    validate = (value: any, validationRules: IValidationRules): any => {
        let isValid: boolean = true;
        const errors: string[] = [];

        if (validationRules && typeof value === 'string') {
            if (validationRules.required && (!value || (value && value.trim() === ''))) {
                isValid = false;
                errors.push('Field is required');
            }

            if (validationRules.maxLength && value.trim().length > validationRules.maxLength) {
                isValid = false;
                errors.push('Max Length of this field is' + validationRules.maxLength + ' characters');
            }

            if (validationRules.allowedOptions && !validationRules.allowedOptions.includes(value) && !validationRules.allowedOptions.includes('@' + value)) {
                isValid = false;
                errors.push('This is not a valid alias');
            }
        }

        const errorText = errors.length !== 0 ? errors.join(' and ') : undefined;
        return { isValid, errorText };
    };

    /** Returns the JSX Element that has to be rendered as part of the form field. */
    getPolarisElement = (): any => {}

    /** Renders the JSX Content for the VIEW mode */
    renderViewMode = (): JSX.Element => (
        <div style={{ display: 'table' }}>
            <div data-testid={'display-wrapper'} style={{ display: 'table-cell' }}>
                <div className='awsui-util-label'><strong><u>{this.displayConfig.label}</u></strong></div>
                <div>{this.state.displayValue}</div>
            </div>
            {this.displayConfig.editable && this.props.isAuthorizedToEdit ? (<IconButton onClick={() => this.setState({ displayMode: DisplayMode.EDIT })}><EditIcon fontSize='large'/></IconButton>) : null}
        </div>
    );

    /** Renders the JSX Content for the CREATE mode */
    renderCreateMode = () => (
        <FormField
            data-testid={'create-wrapper'}
            label={this.displayConfig.label}
            hintText={this.displayConfig.hintText}
            secondaryControl={undefined}
            stretch={false}
            errorText={(!this.state.validity && this.displayConfig.touched) ? this.displayConfig.errorText : undefined}>
            {this.getPolarisElement()}
        </FormField>
    );

    /** 
     * Function that is triggered whenever the edit submit button is clicked. Usually the backend API call
     * should be made here to submit the most updated value.
     */
    editSubmitButtonClicked = () => {}

    /** Function that is triggered when the edit cancel button is clicked */
    editCancelButtonClicked = () => { this.setState({ displayMode: DisplayMode.VIEW }); }

    /** Renders the JSX Content for the EDIT mode */
    renderEditMode = () => (
        <FormField
            data-testid={'edit-wrapper'}
            label={this.displayConfig.label}
            hintText={this.displayConfig.hintText}
            secondaryControl={(
                <div style={{ height: '100%', display: 'inline', padding: '0 10px' }} data-testid='edit-button-group'>
                    <span className='awsui-util-status-positive'>
                        <Button
                            icon='status-positive'
                            variant='icon'
                            onClick={() => this.editSubmitButtonClicked()}
                            loading={this.state.editInProgress ? this.state.editInProgress : false}/>
                    </span>
                    <span className='awsui-util-status-negative'>
                        <Button
                            icon='status-negative'
                            variant='icon'
                            onClick={() => this.editCancelButtonClicked()}
                            loading={this.state.editInProgress ? this.state.editInProgress : false}/>
                    </span>
                </div>
            )}
            stretch={false}
            errorText={(!this.state.validity && this.displayConfig.touched) ? this.displayConfig.errorText : undefined}>
            {this.getPolarisElement()}
        </FormField>
    );

    /** Renders the JSX Content. */
    render = () => {
        switch(this.state.displayMode) {
            case DisplayMode.VIEW:
                return this.renderViewMode();
            case DisplayMode.EDIT:
                return this.renderEditMode();
            default:
                return this.renderCreateMode();
        }
    }

}