import React, { FunctionComponent } from 'react';
import { SideNavigation } from '@amzn/awsui-components-react/polaris';
import { basicPages, adminPages } from '../pages';
import { IProps } from '../interfaces/IProps';
import { ExternalLinks } from '../constants/display/external-links';

interface SidebarProps extends IProps {
    activeHref: string,
    isAdminPortal: boolean
}

export const Sidebar: FunctionComponent<SidebarProps> = ({ activeHref, isAdminPortal }) => {
    const menuItems: SideNavigation.Link[] = [];
    let pagesToRender = isAdminPortal ? adminPages : basicPages;
    pagesToRender.filter((page) => !page.hideTab).forEach((page) => {
        menuItems.push({
            type: 'link',
            text: page.title,
            href: page.path,
            external: false
        });
    });

    const externalItems: SideNavigation.Link[] = [
        { type: 'link', ...ExternalLinks.WIKI },
        { type: 'link', ...ExternalLinks.FEEDBACK_INTAKE },
        { type: 'link', ...ExternalLinks.TT }
    ];

    return (
        <SideNavigation
            activeHref={activeHref}
            items={[
                {
                    type: 'section',
                    text: 'Menu',
                    items: menuItems
                },
                {
                    type: 'section',
                    text: 'External Links',
                    items: externalItems
                }
            ]}/>
    );
};