import React from 'react';
import {
    Marketplace,
    Title,
    Description,
    ProductFamily,
    PrimaryBusinessGroup,
    SecondaryBusinessGroups,
    PrimaryOwner,
    SecondaryOwners,
    Observers,
    Tags,
    ProductSelectionFile,
    RablRegionId,
    Discriminator,
    StartDate,
    EndDate,
    BusinessApprover
} from '../form/attributes';
import { LimestoneExperimentMetadata, LimestoneExperimentSelection } from '../interfaces/LimestoneExperiment';
import { DisplayMode } from '../interfaces/FormAttribute';
import { Realm } from '../api/api-constants';

export class FormFactory {
    public getMetadataForm = (
        formStateChangeFunction?: any,
        displayMode?: DisplayMode,
        isAuthorizedToEdit?: boolean,
        metadata?: LimestoneExperimentMetadata,
        experimentId?: string,
        realm?: Realm): JSX.Element => ( 
        <div data-awsui-column-layout-root='true'>
            <Marketplace updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.marketplace.payloadValue} experimentId={experimentId} realm={realm}/>
            <Title updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.title.payloadValue} experimentId={experimentId} realm={realm}/>
            <Description updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.description.payloadValue} experimentId={experimentId} realm={realm}/>
            <ProductFamily updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.productFamily.payloadValue} experimentId={experimentId} realm={realm}/>
            <PrimaryBusinessGroup updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.primaryBusinessGroup.payloadValue} experimentId={experimentId} realm={realm}/>
            <SecondaryBusinessGroups updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.secondaryBusinessGroups.payloadValue} experimentId={experimentId} removeOption={metadata?.primaryBusinessGroup.displayValue} realm={realm}/>
            <PrimaryOwner updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.primaryOwner.payloadValue} experimentId={experimentId} realm={realm}/>
            <SecondaryOwners updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.secondaryOwners.payloadValue} experimentId={experimentId} realm={realm}/>
            <Observers updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.observers.payloadValue} experimentId={experimentId} realm={realm}/>
            <Tags updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.tags.payloadValue} experimentId={experimentId} realm={realm}/>
            <RablRegionId updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.rablRegionId.payloadValue} experimentId={experimentId} realm={realm}/>
            <Discriminator updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.discriminator.payloadValue} experimentId={experimentId} realm={realm}/>
            <StartDate updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.startDate.payloadValue} experimentId={experimentId} realm={realm} endDate={metadata?.endDate.payloadValue}/>
            <EndDate updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.endDate.payloadValue} experimentId={experimentId} realm={realm} startDate={metadata?.startDate.payloadValue}/>
            <BusinessApprover updateFormState={formStateChangeFunction} displayMode={displayMode} isAuthorizedToEdit={isAuthorizedToEdit} initialValue={metadata?.endDate.payloadValue} experimentId={experimentId} realm={realm}/>
        </div>
    );

    public getProductSelectionForm = (
        formStateChangeFunction?: any,
        displayMode?: DisplayMode,
        isAuthorizedToEdit?: boolean,
        selection?: LimestoneExperimentSelection,
        experimentId?: string,
        marketplaceId?: string,
        status?: string): JSX.Element => ( 
        <div data-awsui-column-layout-root='true'>
            <ProductSelectionFile
                updateFormState={formStateChangeFunction}
                isAuthorizedToEdit={isAuthorizedToEdit}
                displayMode={displayMode}
                initialValue={selection?.offersFile.payloadValue}
                experimentId={experimentId}
                marketplaceId={marketplaceId}
                experimentStatus={status}/>
        </div>
    );
}