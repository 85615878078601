import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export interface DataTableDefinition {
    tableName: string;
    columnNames: string[];
    rows: any[][];
}

export interface DataTableProps {
    tableDefinition: DataTableDefinition;
    expandableSection?: boolean;
    firstColumnHeader?: boolean;
}

const StyledTableCell = withStyles(() =>
    createStyles({
        head: {
            padding: '10px',
            fontSize: 12,
            textAlign: 'left',
            maxWidth: '100px',
            fontWeight: 'bold'
        },
        body: {
            padding: '10px',
            fontSize: 12,
            textAlign: 'left'
        },
    }),
)(TableCell);

const StyledBoldTableCell = withStyles(() =>
    createStyles({
        body: {
            padding: '10px',
            fontSize: 12,
            textAlign: 'left',
            fontWeight: 'bold'
        }
    })
)(TableCell);

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
}));

export const DataTable: FunctionComponent<DataTableProps> = (props: DataTableProps) => {
    const classes = useStyles();
    const table = (
        <Table data-testid={`raw-table-${props.tableDefinition.tableName}`} aria-label="simple table">
            <TableHead>
                <TableRow>
                    {props.tableDefinition.columnNames.map((columnName) => (
                        <StyledTableCell key={columnName}>{columnName}</StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.tableDefinition.rows.length ? 
                    (props.tableDefinition.rows.map((row: any[], index) => (
                        <TableRow key={index}>
                            {props.firstColumnHeader ? 
                                (<StyledBoldTableCell component="th" scope="row">{row[0]}</StyledBoldTableCell>) :
                                (<StyledTableCell component="th" scope="row">{row[0]}</StyledTableCell>)
                            }
                            {row.slice(1, row.length).map((cell, index) => <StyledTableCell key={index}>{cell}</StyledTableCell>)}
                        </TableRow>))
                    ) :
                    (
                        <TableRow><StyledTableCell component="th" scope="row">{'No data found'}</StyledTableCell></TableRow>
                    )
                }
            </TableBody>
        </Table>
    );

    const component = props.expandableSection ? (
        <div className={classes.root}>
            <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant='h4'>{props.tableDefinition.tableName}</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    {table}
                </AccordionDetails>
            </Accordion>
        </div>
    ) : (
        <div>
            <Typography variant='h4'>{props.tableDefinition.tableName}</Typography>
            {table}
        </div>
    );

    return component;
};