import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import ErrorIcon from '@material-ui/icons/Error';
import CreateIcon from '@material-ui/icons/Create';
import PageviewIcon from '@material-ui/icons/Pageview';
import TableIcon from '@material-ui/icons/TableChart';
import { Page } from '../interfaces/Page';
import { HomePage } from './Home/HomePage';
import { HomePageHeader } from './Home/HomePageHeader';
import { PageNotFound } from './ErrorPages/PageNotFound';
import CreateExperimentWizard from './CreateExperiment/CreateWizard';
import ExperimentDetailPage from './ExperimentDetails/ExperimentDetailsPage';
import { MyExperimentsPage } from './MyExperiments/MyExperimentsPage';
import { UpdateStaticConfigData } from './UpdateStaticConfigData/UpdateStaticConfigData';
import { AllExperimentsPage } from './AllExperiments/AllExperimentsPage';
import { AsinActionPage } from './AsinAction/AsinActionPage';
import ApproverDetailPage from './ApproverDetailPage/ApproverDetailPage';
import { ApproversQueue } from './ApproversQueue/ApproversQueue';
import { Realm, Portal } from '../api/api-constants';
import { TransactionalMetrics } from './TransactionalMetrics/TransactionalMetrics';

export const homePage: Page = {
    caption: 'The homepage for Limestone',
    content: <HomePage realm={Realm.NA} portal={Portal.BASIC} testId='home' />,
    contentType: 'cards',
    header: <HomePageHeader />,
    icon: HomeIcon,
    matchExactPath: true,
    path: '/',
    title: 'Home',
    testId: 'home'
};

export const createExperimentPage: Page = {
    caption: 'Create a Limestone Experiment',
    content: <CreateExperimentWizard realm={Realm.NA} portal={Portal.BASIC}/>,
    contentType: 'default',
    icon: CreateIcon,
    matchExactPath: true,
    path: '/create-experiment',
    title: 'Create Experiment',
    testId: 'create'
};

export const experimentDetailPage: Page = {
    caption: 'Below is the summary of the Experiment',
    content: <ExperimentDetailPage realm={Realm.NA} portal={Portal.BASIC}/>,
    contentType: 'default',
    hideTab: true,
    icon: PageviewIcon,
    matchExactPath: true,
    path: '/display-experiment',
    title: 'Experiment Detail Page',
    testId: 'display'
};

export const myExperimentsPage: Page = {
    caption: 'View My Experiments',
    content: <MyExperimentsPage realm={Realm.NA} portal={Portal.BASIC}/>,
    contentType: 'table',
    icon: TableIcon,
    matchExactPath: true,
    path: '/my-experiments',
    title: 'My Experiments',
    testId: 'view'
};

export const approversQueue: Page = {
    caption: 'View Experiments in my Approval Queue',
    content: <ApproversQueue realm={Realm.NA} portal={Portal.BASIC}/>,
    contentType: 'table',
    icon: TableIcon,
    matchExactPath: true,
    path: '/approvers-queue',
    title: 'Approvers Queue',
    testId: 'approvers'
};

export const approverDetailPage: Page = {
    caption: 'Below is the summary of the Experiment',
    content: <ApproverDetailPage realm={Realm.NA} portal={Portal.BASIC}/>,
    contentType: 'default',
    hideTab: true,
    icon: PageviewIcon,
    matchExactPath: true,
    path: '/approver-detail',
    title: 'Approver Detail Page',
    testId: 'approver'
};

export const allExperimentsPage: Page = {
    caption: 'View All Experiments',
    content: <AllExperimentsPage realm={Realm.NA} portal={Portal.ADMIN}/>,
    contentType: 'table',
    icon: TableIcon,
    isAdminPage: true,
    matchExactPath: true,
    path: '/all-experiments',
    title: 'All Experiments',
    testId: 'view'
};

export const updateStaticConfigDataPage: Page = {
    caption: 'Update Static Configuration Data',
    content: <UpdateStaticConfigData realm={Realm.NA} portal={Portal.ADMIN}/>,
    contentType: 'default',
    hideTab: false,
    isAdminPage: true,
    icon: PageviewIcon,
    matchExactPath: false,
    path: '/static-config-data',
    title: 'Static Configuration Data',
    testId: 'static-config'
};

export const asinActionPage: Page = {
    caption: 'Perform Asin level Actions',
    content: <AsinActionPage realm={Realm.NA} portal={Portal.ADMIN} />,
    contentType: 'default',
    hideTab: true,
    icon: PageviewIcon,
    isAdminPage: true,
    matchExactPath: false,
    path: '/asin-action',
    title: 'Asin Action',
    testId: 'asin-action'
};

export const transactionalMetricsPage: Page = {
    caption: 'Transactional Metrics',
    content: <TransactionalMetrics realm={Realm.NA} portal={Portal.BASIC}/>,
    contentType: 'default',
    hideTab: true,
    icon: PageviewIcon,
    isAdminPage: false,
    matchExactPath: false,
    path: '/transactional-metrics',
    title: 'Transactional Metrics',
    testId: 'transactional-metrics'
};

export const errorPage: Page = {
    caption: 'The page you are trying to reach does not exist',
    content: <PageNotFound />,
    contentType: 'default',
    hideTab: true,
    icon: ErrorIcon,
    matchExactPath: false,
    parentPage: homePage,
    path: '*',
    title: 'Error',
    testId: 'error'
};

export const basicPages: Page[] = [homePage, createExperimentPage, experimentDetailPage, myExperimentsPage, approversQueue, approverDetailPage, transactionalMetricsPage, errorPage];
export const adminPages: Page[] = [homePage, allExperimentsPage, experimentDetailPage, asinActionPage, updateStaticConfigDataPage, transactionalMetricsPage, errorPage];