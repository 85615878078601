export default {
    'aws_project_region': 'us-east-1',
    'aws_cognito_identity_pool_id': 'us-east-1:64d59782-127f-4fd2-a487-2f05e860ce86',
    'aws_cognito_region': 'us-east-1',
    'aws_user_pools_id': 'us-east-1_1kPLF55tw',
    'aws_user_pools_web_client_id': '3a1auqenf8t5m3pumm79j91fs2',
    'oauth': {
        'domain': 'limestone-dev.auth.us-east-1.amazoncognito.com',
        'scope': [
            'openid',
            'profile',
            'aws.cognito.signin.user.admin'
        ],
        'redirectSignIn': 'http://localhost:3000',
        'redirectSignOut': 'http://localhost:3000',
        // Uncomment this line & Comment the above 2 for testing on Limestone-Devo
        // 'redirectSignIn': 'https://test.limestone.strx.amazon.dev',
        // 'redirectSignOut': 'https://test.limestone.strx.amazon.dev',
        'responseType': 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    'federationTarget': 'COGNITO_USER_POOLS',
};