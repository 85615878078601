import React, { Fragment, FunctionComponent, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import RealmContext from '../context/RealmContext';
import UserContext from '../context/UserContext';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { pageMargin, pagePadding } from '../constants/display/page-size';
import { IProps } from '../interfaces/IProps';
import { Realm } from '../api/api-constants';
import { Page } from '../interfaces/Page';
import { Portal } from '../api/api-constants';
import { myExperimentsPage, homePage } from '../pages';
import { experimentDetailPage } from '../pages/index';
import { AdminHeader, BasicHeader } from '../constants/display/website-headers';

interface WebsiteHeaderProps extends IProps {
    page: Page;
    username: string;
    isAdminPortal: Boolean;
}

export const useBasicStyles = makeStyles((theme) => ({
    appBar: {
        transition: 'background-color ease 0.2s'
    },
    brand: {
        fontSize: '2.5rem',
        color: theme.palette.secondary.main,
        gridColumn: '1',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: theme.palette.secondary.main
        },
    },
    user: {
        float: 'left',
        display: 'flex',
        gridColumn: '2',
        margin: '15px'
    },
    rightAlignedComponents: {
        float: 'right',
        display: 'flex',
        gridColumn: '3'
    },
    realmDisplay: {
        color: 'white'
    },
    toggleButtonDisplay: {
        color: 'white',
        fontSize: 10
    },
    toolbar: {
        display: 'grid',
        gridTemplateColumns: 'max-content auto max-content',
        margin: pageMargin,
        padding: '0',
        width: `calc(100% - 2 * ${pagePadding})`
    },
}));

export const useAdminStyles = makeStyles((theme) => ({
    appBar: {
        transition: 'background-color ease 0.2s'
    },
    brand: {
        fontSize: '2.5rem',
        color: theme.palette.primary.main,
        gridColumn: '1',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: theme.palette.primary.main
        },
    },
    user: {
        float: 'left',
        display: 'flex',
        gridColumn: '2',
        margin: '15px'
    },
    rightAlignedComponents: {
        float: 'right',
        display: 'flex',
        gridColumn: '3'
    },
    realmDisplay: {
        color: 'black'
    },
    toggleButtonDisplay: {

    },
    toolbar: {
        display: 'grid',
        background: theme.palette.secondary.main,
        gridTemplateColumns: 'max-content auto max-content',
        padding: '30',
        width: '100%'
    }
}));

export const WebsiteHeader: FunctionComponent<WebsiteHeaderProps> = ({ testId, username, page, isAdminPortal }) => {
    const [menuAnchor, setMenuAnchor] = useState(null);

    const { realm, toggleRealm } = useContext(RealmContext);
    const userContext = useContext(UserContext);

    const userAlias = userContext.username;
    const basicStyleClass = useBasicStyles();
    const adminStyleClass = useAdminStyles();

    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });

    const handleClick = (event: any) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleChange = (newRealm: Realm) => {
        toggleRealm(newRealm);
        setMenuAnchor(null);
    };

    const handlePortalChange = (newPortal: Portal) => {
        userContext.togglePortal(newPortal);
    };

    let classes = (isAdminPortal) ? adminStyleClass : basicStyleClass;
    let headerContents = (isAdminPortal) ? AdminHeader : BasicHeader;

    const realmMenuDisplay = (page.path === homePage.path || page.path === myExperimentsPage.path || page.path === experimentDetailPage.path) ?
        (<Fragment>
            <Button aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick} color='primary'>
                <Typography variant='h6' className={classes.realmDisplay}>
                    {realm.toUpperCase()}
                    <KeyboardArrowDownIcon />
                </Typography>
            </Button>
            <Menu
                id='simple-menu'
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={() => handleChange(realm)}>
                <MenuItem onClick={() => handleChange(Realm.NA)}>NA</MenuItem>
                <MenuItem onClick={() => handleChange(Realm.EU)}>EU</MenuItem>
            </Menu>
        </Fragment>) : 
        (<Typography variant='h6' className={classes.realmDisplay}>{`REALM: ${realm.toUpperCase()}`}</Typography>);

    const portalDisplay = (page.path === homePage.path) ? (
        <Button onClick={() => handlePortalChange(headerContents.targetPortal)} className={classes.toggleButtonDisplay}>
            {headerContents.targetName}
        </Button>) : null;

    const header = (
        <AppBar data-testid={`${testId}-websiteheader`} className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <Link to={homePage.path}>
                    <span className={classes.brand}> {headerContents.title} </span>
                </Link>
                <Typography className={classes.user}>
                    <span className={classes.user}>{`User: ${username ? username: 'Unidentified'} (${userAlias})`}</span>
                </Typography>
                <span className={classes.rightAlignedComponents}>
                    {realmMenuDisplay}
                    {portalDisplay}
                </span>
            </Toolbar>
        </AppBar>
    );

    /* istanbul ignore next */
    const elevatedHeader = React.cloneElement(header, { elevation: trigger ? 4 : 0 });

    return (
        <Fragment>
            {elevatedHeader}
            <Toolbar />
        </Fragment>
    );
};