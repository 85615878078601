import React from 'react';
import { Table } from '@amzn/awsui-components-react/polaris';
import { LimestoneExperiment } from '../../interfaces/LimestoneExperiment';
import { Link } from 'react-router-dom';
import { approverDetailPage } from '../../pages';

export const columnDefinitions: Array<Table.ColumnDefinition<LimestoneExperiment>> = [
    {
        id: 'Title',
        header: 'Title',
        cell: (item) => <Link to={`${approverDetailPage.path}?experimentId=${item.experimentId}&experimentIntegerId=${item.experimentIntegerId}`}>{item.metadata.title.displayValue}</Link>,
        width: 200
    },
    {
        id: 'ExperimentId',
        header: 'ExperimentId',
        cell: (item) => item.experimentId,
        width: 350
    },
    {
        id: 'Product Family',
        header: 'Product Family',
        cell: (item) => item.metadata.productFamily.displayValue,
        width: 150
    },
    {
        id: 'Primary Business Group',
        header: 'Primary Business Group',
        cell: (item) => item.metadata.primaryBusinessGroup.displayValue,
        width: 200
    },
    {
        id: 'Primary Owner',
        header: 'Primary Owner',
        cell: (item) => item.metadata.primaryOwner.displayValue,
        width: 150
    },
    {
        id: 'Marketplace',
        header: 'Marketplace',
        cell: (item) => item.metadata.marketplace.displayValue,
        width: 130
    },
    {
        id: 'Tags',
        header: 'Tags',
        cell: (item) => item.metadata.tags.displayValue,
        width: 150
    },
    {
        id: 'Creation Date',
        header: 'Creation Date',
        cell: (item) => item.creationDate,
        width: 150
    },
    {
        id: 'Description',
        header: 'Description',
        cell: (item) => item.metadata.description.displayValue,
        width: 200,
        allowLineWrap: true
    },
    {
        id: 'Last Updated Date',
        header: 'Last Updated Date',
        cell: (item) => item.lastUpdatedDate,
        width: 100
    },
];

export const columnOptions = [{
    label: 'Experiment Metadata',
    options: [
        { id: 'Title', label: 'Title', editable: false, visible: true },
        { id: 'ExperimentId', label: 'ExperimentId', editable: true, visible: false },
        { id: 'Product Family', label: 'Product Family', editable: true, visible: true },
        { id: 'Primary Business Group', label: 'Primary Business Group', editable: true, visible: true },
        { id: 'Primary Owner', label: 'Primary Owner', editable: true, visible: true },
        { id: 'Marketplace', label: 'Marketplace', editable: true, visible: true },
        { id: 'Tags', label: 'Tags', editable: true, visible: true },
        { id: 'Creation Date', label: 'Creation Date', editable: true, visible: true },
        { id: 'Last Updated Date', label: 'Last Updated Date', editable: true, visible: false },
        { id: 'Description', label: 'Description', editable: true, visible: true },
    ]
}];

export const pageSizeOptions = [
    { value: 10, label: '10 experiments' },
    { value: 15, label: '15 experiments' },
    { value: 20, label: '20 experiments' }];
