import { Flashbar } from '@amzn/awsui-components-react/polaris';

export interface FlashbarMessageDefinition extends Flashbar.MessageDefinition {
    baseContent: string;
    id: string;
}

interface FlashbarApiResponse {
    SUCCESS: FlashbarMessageDefinition;
    FAIL: FlashbarMessageDefinition;
}

enum NotificationMessageType {
    SUCCESS = 'success',
    FAIL = 'error',
    WARNING = 'warning',
    INFO = 'info'
}

export const uploadMetadata: FlashbarApiResponse = {
    SUCCESS: {
        id: 'uploadMetadataSuccess',
        header: 'Success!',
        baseContent: 'Experiment definition has been uploaded successfully',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'uploadMetadataFailure',
        header: 'Failed',
        baseContent: 'There was an error while uploading definition',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const uploadProductSelection: FlashbarApiResponse = {
    SUCCESS: {
        id: 'uploadProductSelectionSuccess',
        header: 'Success!',
        baseContent: 'Product Selection has been uploaded successfully. You will be redirected to the Experiment Detail Page in 3 seconds...',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'uploadProductSelectionFailure',
        header: 'Failed',
        baseContent: 'There was an error while uploading product selection',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const startExperiment: FlashbarApiResponse = {
    SUCCESS: {
        id: 'startExperimentSuccess',
        header: 'Success!',
        baseContent: 'Experiment is now starting. Refresh the page to get the most updated Experiment Status.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'startExperimentFailure',
        header: 'Failed',
        baseContent: 'There was an error while starting the experiment',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const endExperiment: FlashbarApiResponse = {
    SUCCESS: {
        id: 'endExperimentSuccess',
        header: 'Success!',
        baseContent: 'Experiment is now ending. Refresh the page to get the most updated Experiment Status.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'endExperimentFailure',
        header: 'Failed',
        baseContent: 'There was an error while ending the experiment',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const updateExperimentTitle: FlashbarApiResponse = {
    SUCCESS: {
        id: 'updateExperimentTitleSuccess',
        header: 'Success!',
        baseContent: 'Experiment Title has been updated successfully.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'updateExperimentTitleFailure',
        header: 'Failed',
        baseContent: 'There was an error in updating the experiment title',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const updateExperimentDescription: FlashbarApiResponse = {
    SUCCESS: {
        id: 'updateExperimentDescriptionSuccess',
        header: 'Success!',
        baseContent: 'Experiment Description has been updated successfully.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'updateExperimentDescriptionFailure',
        header: 'Failed',
        baseContent: 'There was an error in updating the Experiment Description ',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const readExperiment: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'readExperimentFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting the experiment',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentsByOwner: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getExperimentsByOwnerFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting your experiments',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentsInGivenStatuses: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getExperimentsInGivenStatusesFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting your experiments',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentBoundaries: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getExperimentBoundariesFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting the experiment regions',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const submitExperimentBoundaries: FlashbarApiResponse = {
    SUCCESS: {
        id: 'submitExperimentBoundariesSuccess',
        header: 'Success!',
        baseContent: 'Treatment Region is now submitted. Refresh the page to get the most updated Experiment Status.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'submitExperimentBoundariesFailure',
        header: 'Failed',
        baseContent: 'There was an error in submitting the treatment region',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentsByApprover: FlashbarApiResponse = {
    SUCCESS: {
        id: 'getExperimentsByApproverSuccess',
        header: 'Success!',
        baseContent: 'Successfully retrieved experiments for approver',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'getExperimentsByApproverFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting experiments',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const submitApprovalDecision: FlashbarApiResponse = {
    SUCCESS: {
        id: 'submitApprovalDecisionSuccess',
        header: 'Success!',
        baseContent: 'Successfully submitted your approval decision.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'submitApprovalDecisionFailure',
        header: 'Failed',
        baseContent: 'There was an error in submitting your approval decision',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getAllExperimentBoundaries: FlashbarApiResponse = {
    SUCCESS: {
        id: 'getAllExperimentBoundariesSuccess',
        header: 'Success!',
        baseContent: 'Successfully retrieved experiment approvers',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'getAllExperimentBoundariesFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting your experiment approvers',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getStaticConfigurationData: FlashbarApiResponse = {
    SUCCESS: {
        id: 'getStaticConfigurationDataSuccess',
        header: 'Success!',
        baseContent: 'Successfully retrieved static configurations',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'getStaticConfigurationDataFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting your static configuration data',
        type: NotificationMessageType.FAIL,
        dismissible: true 
    }
};

export const updateStaticConfigurationData: FlashbarApiResponse = {
    SUCCESS: {
        id: 'updateStaticConfigurationDataSuccess',
        header: 'Success!',
        baseContent: 'Successfully updated and deployed static configurations! Your configuration will be baking in the next 10 minutes, so DO NOT make another update in that time',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'updateStaticConfigurationDataFailure',
        header: 'Failed',
        baseContent: 'There was an error in updating your static configuration data',
        type: NotificationMessageType.FAIL,
        dismissible: true 
    }
};

export const getExperimentById: FlashbarApiResponse = {
    SUCCESS: {
        id: 'getExperimentByIdSuccess',
        header: 'Success!',
        baseContent: 'Successfully retrieved experiment!',
        type: NotificationMessageType.FAIL,
        dismissible: true
    },
    FAIL: {
        id: 'getExperimentByIdFail',
        header: 'Failed',
        baseContent: 'There an error in getting your experiment',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const createExperimentUserRequest: FlashbarApiResponse = {
    SUCCESS: {
        id: 'createExperimentUserRequestSuccess',
        header: 'Success!',
        baseContent: 'Successfully created experiment user request!',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'createEperimentUserRequestFail',
        header: 'Failed',
        baseContent: 'There was an error in creating your experiment user request',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentUserRequest: FlashbarApiResponse = {
    SUCCESS: {
        id: 'getExperimentUserRequestSuccess',
        header: 'Success!',
        baseContent: 'Successfully retrieved experiment user request list!',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'getEperimentUserRequestFail',
        header: 'Failed',
        baseContent: 'There was an error in getting your experiment user request list',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentUserRequestOffers: FlashbarApiResponse = {
    SUCCESS: {
        id: 'getExperimentUserRequestOffersSuccess',
        header: 'Success!',
        baseContent: 'Successfully retrieved experiment user request offers for current request!',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'getEperimentUserRequestOffersFail',
        header: 'Failed',
        baseContent: 'There was an error in getting your experiment user request offers for current request',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const executeExperimentUserRequest: FlashbarApiResponse = {
    SUCCESS: {
        id: 'executeExperimentUserRequestOffersSuccess',
        header: 'Success!',
        baseContent: 'Successfully executed user request!',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'executeEperimentUserRequestOffersFail',
        header: 'Failed',
        baseContent: 'There was an error in executing your user request',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getTransactionalMetrics: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getTransactionalMetricsFail',
        header: 'Failed',
        baseContent: 'There was an error in getting transactional metrics for the experiment.',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getDownstreamMetrics: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getDownstreamMetricsFail',
        header: 'Failed',
        baseContent: 'There was an error in getting downstream metrics for the experiment.',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const simulationResultsReady: Partial<FlashbarApiResponse> = {
    SUCCESS: {
        id: 'simulationResultsReady',
        header: 'Note: Treatment regions options are ready.',
        baseContent: 'Our simulation system has generated a set of treatment regions for you to select, please scroll down to the below sections.',
        type: NotificationMessageType.INFO,
        dismissible: false
    }
};