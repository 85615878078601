import * as LambdaModel from '../lambda-model-types';
import { LimestoneExperimentMetadata } from '../../../interfaces/LimestoneExperiment';

export const convertMetadataToPayload = (metadata: LimestoneExperimentMetadata): LambdaModel.CreateExperimentPayload => {
    return {
        experimentMetadata: {
            marketplaceId: metadata.marketplace.payloadValue,
            title: metadata.title.payloadValue,
            description: metadata.description.payloadValue,
            productFamily: metadata.productFamily.payloadValue,
            businessGroupsDto: [...metadata.secondaryBusinessGroups.payloadValue, metadata.primaryBusinessGroup.payloadValue],
            ownersDto: [...metadata.secondaryOwners.payloadValue, metadata.primaryOwner.payloadValue],
            observersDto: metadata.observers.payloadValue,
            tagsDto: metadata.tags.payloadValue,
            regionId: metadata.rablRegionId.payloadValue,
            listerDiscriminator: metadata.discriminator.payloadValue,
            experimentStartDate: metadata.startDate.payloadValue,
            experimentEndDate: metadata.endDate.payloadValue,
            businessApprover: metadata.businessApprover.payloadValue
        }
    };
};