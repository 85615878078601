import React, { Component } from 'react';
import { Button, ColumnLayout, Spinner, ExpandableSection, TableSelection } from '@amzn/awsui-components-react/polaris';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { StepDetailsQueryParams } from '../../api/api-constants';
import { LimestoneExperiment, LimestoneExperimentBoundaries } from '../../interfaces/LimestoneExperiment';
import { IButtonHandler } from '../../interfaces/IButtonHandler';
import * as NOTIFICATION_MESSAGES from '../../constants/display/flashbar-messages';
import * as FormUtils from '../../utils/form-utils';
import * as UrlUrils from '../../utils/url-utils';
import { ExperimentStatusType } from '../../enums/ExperimentStatus';
import UserContext from '../../context/UserContext';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import { PageProps } from '../../interfaces/IProps';
import { FormFactory } from '../../factories/FormFactory';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { RablRegionCreationStatusType } from '../../enums/RablRegionCreationStatus';
import { UserInputModal } from '../../common/UserInputModal';
import { SubmitBoundariesModalAttributes, EndExperimentModalAttributes, StartExperimentModalAttributes } from '../../constants/display/modal-constants';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import { Approver } from '../../interfaces/Approver';
import { asinActionPage, transactionalMetricsPage } from '../index';
import { DisplayTable } from '../../common/DisplayTable';
import { getColumnDefinitions, getColumnOptions, pageSizeOptions } from '../../constants/table/boundary-set-attributes';
import { BoundarySet } from '../../interfaces/BoundarySet';
import { ExperimentWorkflowType } from '../../common/ExperimentWorkflowType';

export interface ExperimentDetailPageProps extends RouteComponentProps, PageProps {}

export interface ExperimentDetailPageState {
    experimentId: string;
    experimentIntegerId: number;
    experiment: LimestoneExperiment,
    approvers: Approver[],
    downloadButtonLoading: boolean,
    startButtonDisabled: boolean,
    startButtonLoading: boolean,
    endButtonDisabled: boolean,
    endButtonLoading: boolean,
    boundarySets: BoundarySet[],
    selectedBoundarySet: string[],
    awaitingUserBoundariesResponse: boolean,
    showEndExperimentModal: boolean,
    showStartExperimentModal: boolean,
    submitBoundariesButtonDisabled: boolean;
    submitBoundariesButtonLoading: boolean;
    viewMetricsButtonDisabled: boolean;
    showSubmitBoundariesModal: boolean;
    showSpinner: boolean
}

export class ExperimentDetailPage extends Component<ExperimentDetailPageProps, ExperimentDetailPageState> {
    private readonly buttonHandlers: any;
    private readonly endExperimentModalHandlers: IButtonHandler;
    private readonly startExperimentModalHandlers: IButtonHandler;
    private readonly submitBoundariesModalHandlers: IButtonHandler;

    /** Experiment Service handler instance which provides api to get the experiment data from the backend */
    public experimentServiceAPI: LemsApiHandler;

    /** Factory class which returns the appropriate form. */
    private formFactory: FormFactory;

    static contextType = UserContext;

    public constructor(props: any) {
        super(props);

        this.experimentServiceAPI = new ApiHandler(props.realm);
        this.formFactory = new FormFactory();

        this.state = {
            experimentId: '',
            experimentIntegerId: -1,
            showSpinner: false,
            experiment: FormUtils.createEmptyLimestoneExperiment(),
            approvers: [],
            downloadButtonLoading: false,
            endButtonDisabled: true,
            startButtonDisabled: true,
            startButtonLoading: false,
            endButtonLoading: false,
            boundarySets: [],
            selectedBoundarySet: [],
            showEndExperimentModal: false,
            showStartExperimentModal: false,
            awaitingUserBoundariesResponse: false,
            submitBoundariesButtonDisabled: true,
            submitBoundariesButtonLoading: false,
            showSubmitBoundariesModal: false,
            viewMetricsButtonDisabled: true
        };

        this.experimentServiceAPI = new ApiHandler(props.realm);

        this.buttonHandlers = {
            start: () => this.setState({ showStartExperimentModal: true }),
            end: () => this.setState({ showEndExperimentModal: true }),
            submitBoundaries: () => this.setState({ showSubmitBoundariesModal: true })
        };

        this.startExperimentModalHandlers = {
            dismiss: () => this.setState({ showStartExperimentModal: false }),
            submit: () => this.startExperiment()   
        };

        this.endExperimentModalHandlers = {
            dismiss: () => this.setState({ showEndExperimentModal: false }),
            submit: () => this.endExperiment()
        };

        this.submitBoundariesModalHandlers = {
            dismiss: () => this.setState({ showSubmitBoundariesModal: false }),
            submit: () => this.submitFinalBoundaries()
        };
    }

    /* istanbul ignore next */
    componentDidMount = async() => {
        const { experimentId, experimentIntegerId }: StepDetailsQueryParams = UrlUrils.parseStepDetailsFromUrl(new URL(window.location.href));
        this.setState({ showSpinner: true });

        if (experimentId && experimentIntegerId) {
            this.setState({ experimentId, experimentIntegerId });

            const experiment = await this.experimentServiceAPI.readExperiment(experimentId, experimentIntegerId)
                .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.readExperiment.FAIL!));

            if (experiment) {
                this.setState({ experiment });
                this.setButtonsDisabledState(experiment);

                if (experiment.metadata.regionCreationStatus.payloadValue === RablRegionCreationStatusType.AWAITING_USER_RESPONSE) {
                    await this.experimentServiceAPI.getExperimentBoundaryOptions(experimentId)
                        .then((boundarySets: BoundarySet[]) => this.setState({ boundarySets }))
                        .catch((error: any) => {
                            handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentBoundaries.FAIL!);
                        });
                    
                    this.setState({ submitBoundariesButtonDisabled: false, awaitingUserBoundariesResponse: true });
                    this.props.setNotification!(NOTIFICATION_MESSAGES.simulationResultsReady.SUCCESS!);
                } else {
                    await this.experimentServiceAPI.getAllExperimentBoundaries(experimentId)
                        .then((response: LimestoneExperimentBoundaries|undefined) => { experiment.regionSelection = response!; })
                        .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentBoundaries.FAIL!));
                }

                await this.experimentServiceAPI.getAllExperimentApprovers(experimentId)
                    .then((response: Approver[]) => this.setState({ approvers: response }))
                    .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getAllExperimentBoundaries.FAIL!))
                    .finally(() => this.setState({ showSpinner: false }));
            }
        }
    }

    setButtonsDisabledState = (experiment: LimestoneExperiment) => {
        const experimentStatus: ExperimentStatusType = experiment.currentStatus.currentStatus.payloadValue;
        if (experimentStatus === ExperimentStatusType.APPROVED && experiment.metadata.regionCreationStatus.payloadValue === RablRegionCreationStatusType.LIVE) {
            this.setState({ startButtonDisabled: false });
        }

        const userAuthorized = this.context.isAdmin || experiment.metadata.primaryOwner.displayValue === this.context.username;
        if (experimentStatus === ExperimentStatusType.RUNNING && userAuthorized) {
            this.setState({ endButtonDisabled: false });
        }

        if (experimentStatus === ExperimentStatusType.RUNNING || experimentStatus === ExperimentStatusType.COMPLETE || experimentStatus === ExperimentStatusType.ENDING_EXPERIMENT) {
            this.setState({ viewMetricsButtonDisabled: false });
        }
    }

    startExperiment = async() => {
        this.setState({ startButtonLoading: true, showStartExperimentModal: false });

        await this.experimentServiceAPI.executeExperiment(this.state.experimentId, ExperimentWorkflowType.REGIONALIZE_ASIN)
            .then(() => this.props.setNotification!(NOTIFICATION_MESSAGES.startExperiment.SUCCESS))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.startExperiment.FAIL!))
            .finally(() => this.setState({ startButtonLoading: false }));
    }

    endExperiment = async() => {
        this.setState({ endButtonLoading: true, showEndExperimentModal: false });

        await this.experimentServiceAPI.executeExperiment(this.state.experimentId, ExperimentWorkflowType.UNREGIONALIZE_ASIN)
            .then(() => this.props.setNotification!(NOTIFICATION_MESSAGES.endExperiment.SUCCESS))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.endExperiment.FAIL!))
            .finally(() => this.setState({ endButtonLoading: false }));
    }

    submitFinalBoundaries = async() => {
        this.setState({ submitBoundariesButtonLoading: true, showSubmitBoundariesModal: false });

        const response = await this.experimentServiceAPI.updateExperimentBoundaries(this.state.experimentId, this.state.selectedBoundarySet)
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.submitExperimentBoundaries.FAIL!))
            .finally(() => this.setState({ submitBoundariesButtonLoading: false }));

        if (response) {
            this.setState({ submitBoundariesButtonLoading: true });

            await this.experimentServiceAPI.finalizeExperimentBoundaries(this.state.experimentId)
                .then(() => {
                    this.props.setNotification!(NOTIFICATION_MESSAGES.submitExperimentBoundaries.SUCCESS);
                    window.location.reload();
                })
                .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.submitExperimentBoundaries.FAIL!))
                .finally(() => this.setState({ submitBoundariesButtonLoading: false }));
        }
    }

    onBoundarySelectionChange = (event: CustomEvent<TableSelection.SelectionChangeDetail<BoundarySet>>) => {
        const boundaries = event.detail.selectedItems[0].boundaries;
        const boundarySet: string[] = Array.from(boundaries.keys()).map((boundaryId) => `${boundaryId}::${boundaries.get(boundaryId)}`);

        this.setState({ selectedBoundarySet: boundarySet });
    }

    render() {
        const columnNames = this.state.boundarySets.length ? Array.from(this.state.boundarySets[0].attributes.keys()) : [];

        const actionStripe = (
            <div className="awsui-util-mb-m awsui-util-mt-xs">
                <div className="awsui-util-action-stripe-large">
                    <div className="awsui-util-action-stripe-title">
                        <h1>{`${this.state.experiment.metadata.title.displayValue} (ID: ${this.state.experiment.experimentIntegerId})`}</h1>
                        <h2>{'Current Status: '}<span>{this.state.experiment.currentStatus.currentStatus.displayValue}</span></h2>
                    </div>
                    <div className="awsui-util-action-stripe-group">
                        <Button
                            data-testid={'start-experiment-button'}
                            loading={this.state.startButtonLoading}
                            disabled={this.state.startButtonDisabled}
                            onClick={this.buttonHandlers.start}
                        >Start Experiment</Button>
                        <Button
                            data-testid={'end-experiment-button'}
                            loading={this.state.endButtonLoading}
                            disabled={this.state.endButtonDisabled}
                            onClick={this.buttonHandlers.end}
                        >End Experiment</Button>
                        {this.props.userAttributes && this.props.userAttributes.isAdmin &&
                            <Button
                                data-testid={'asin-actions-button'}
                                href={`${asinActionPage.path}?experimentId=${this.state.experimentId}&experimentIntegerId=${this.state.experimentIntegerId}`}
                            >ASIN Actions</Button>
                        }
                        <Button
                            data-testid={'transactional-metrics-button'}
                            href={`${transactionalMetricsPage.path}?experimentId=${this.state.experimentId}&experimentIntegerId=${this.state.experimentIntegerId}`} >
                            View Metrics
                        </Button>
                    </div>
                </div>
            </div>);

        const modals = (
            <>
                <UserInputModal 
                    visible={this.state.showStartExperimentModal}
                    buttonHandlers={this.startExperimentModalHandlers}
                    {...StartExperimentModalAttributes}
                />

                <UserInputModal 
                    visible={this.state.showStartExperimentModal}
                    buttonHandlers={this.startExperimentModalHandlers}
                    {...StartExperimentModalAttributes}
                />

                <UserInputModal 
                    visible={this.state.showEndExperimentModal}
                    buttonHandlers={this.endExperimentModalHandlers}
                    {...EndExperimentModalAttributes}
                />

                <UserInputModal 
                    visible={this.state.showSubmitBoundariesModal}
                    buttonHandlers={this.submitBoundariesModalHandlers}
                    {...SubmitBoundariesModalAttributes}
                />
            </>
        );

        const treatmentBoundariesActionStripe = (
            <div className="awsui-util-action-stripe">
                <div className="awsui-util-action-stripe-title">Treatment Region</div>
                <div className="awsui-util-action-stripe-group">
                    <Button
                        data-testid={'submit-boundaries-button'}
                        loading={this.state.submitBoundariesButtonLoading}
                        disabled={this.state.submitBoundariesButtonDisabled}
                        onClick={this.buttonHandlers.submitBoundaries}
                    >Submit Region</Button>
                </div>
            </div>
        );

        const content = this.state.showSpinner ? (<Spinner size='large' />)
            : (<>
                {actionStripe}
                <ExpandableSection header='Experiment Definition' variant='container' expanded={true}>
                    <ColumnLayout columns={4} variant='text-grid'>
                        {this.formFactory.getMetadataForm(undefined, DisplayMode.VIEW, true, this.state.experiment.metadata, this.state.experimentId, this.props.realm)}
                    </ColumnLayout>
                </ExpandableSection>

                <ExpandableSection header='Experiment Selection' variant='container' expanded={true}>
                    <ColumnLayout columns={1} variant='text-grid'>
                        {this.formFactory.getProductSelectionForm(
                            undefined,
                            DisplayMode.VIEW,
                            true,
                            this.state.experiment.productSelection,
                            this.state.experimentId,
                            this.state.experiment.metadata.marketplace.displayValue,
                            this.state.experiment.currentStatus.currentStatus.payloadValue)}
                    </ColumnLayout>
                </ExpandableSection>

                <ExpandableSection header={treatmentBoundariesActionStripe} variant='container' expanded={true}>
                    {this.state.awaitingUserBoundariesResponse && this.state.boundarySets.length ?
                        (
                            <DisplayTable
                                items={this.state.boundarySets}
                                tableLoading={false}
                                tableSelection={this.onBoundarySelectionChange}
                                columnDefinitions={getColumnDefinitions(columnNames)}
                                columnOptions={getColumnOptions(columnNames)}
                                pageSizeOptions={pageSizeOptions}/>
                        ) :
                        (
                            <ColumnLayout columns={2} variant='text-grid'>
                                <div data-awsui-column-layout-root='true'>
                                    <div style={{ display: 'table' }}>
                                        <div data-testid={'display-wrapper'} style={{ display: 'table-cell' }}>
                                            <div className='awsui-util-label'><strong><u>{this.state.experiment.regionSelection.treatmentBoundaries.displayLabel}</u></strong></div>
                                            <div>{this.state.experiment.regionSelection.treatmentBoundaries.displayValue}</div>
                                        </div>
                                    </div>

                                    <div style={{ display: 'table' }}>
                                        <div data-testid={'display-wrapper'} style={{ display: 'table-cell' }}>
                                            <div className='awsui-util-label'><strong><u>{this.state.experiment.metadata.regionCreationStatus.displayLabel}</u></strong></div>
                                            <div>{this.state.experiment.metadata.regionCreationStatus.displayValue}</div>
                                        </div>
                                    </div>
                                </div>
                            </ColumnLayout>
                        )
                    }
                </ExpandableSection>

                <ExpandableSection header='Experiment Approvals' variant='container' data-testid={'experiment-approval-status-section'} expanded={true}>
                    <ColumnLayout columns={4} variant='text-grid'>
                        <div data-awsui-column-layout-root='true'>
                            <div className='awsui-util-label'><strong><u>{'Approver Alias'}</u></strong></div>
                            <div className='awsui-util-label'><strong><u>{'Approver Role'}</u></strong></div>
                            <div><strong><u>{'Approval Status'}</u></strong></div>
                            <div><strong><u>{'Reason'}</u></strong></div>
                        </div>
                    </ColumnLayout>
                    {this.state.approvers.map((approver: Approver) => {
                        return (
                            <div key={approver.alias}>
                                <ColumnLayout columns={4} variant='text-grid'>
                                    <div data-awsui-column-layout-root='true'>
                                        <div className='awsui-util-label'><strong>{approver.alias}</strong></div>
                                        <div className='awsui-util-label'>{approver.role}</div>
                                        <div>{approver.status}</div>
                                        <div>{approver.rejectionReason}</div>
                                    </div>
                                </ColumnLayout>
                            </div>
                        );
                    })}           
                </ExpandableSection>

                {modals}
            </>);
        return (
            <div className="awsui-util-container" style={{ padding: 5 }}>
                {content}
            </div>
        );
    }
}

export default withRouter(ExperimentDetailPage);
