import { SelectField, SelectFieldConfig } from '../fields/SelectField';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { MetadataAttribute } from '../../enums/CommonTypes';
import * as LambdaModel from '../../api/experiment-service/lambda-model-types';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { AttributeLabels } from '../../constants/display/string-constants';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';

export class PrimaryBusinessGroup extends SelectField {
    private experimentServiceAPI: LemsApiHandler;
    protected displayConfig: SelectFieldConfig;
    protected staticConfig: string;
    protected getSuccess: boolean;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true };
        this.staticConfig = '';
        this.getSuccess = false;

        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.PRIMARY_BUSINESS_GROUP,
            editable: false,
            touched: false,
            placeholder: 'Select from the drop-down',
            filteringType: 'auto',
            hintText: 'Required *',
            errorText,
            onChange: (event) => this.onChangeEvent(event, MetadataAttribute.PRIMARY_BUSINESS_GROUP),
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
        this.experimentServiceAPI = new ApiHandler(this.props.realm!);
    }

    handleGetResults() {
        if (this.getSuccess) {
            this.displayConfig.loading = true;

            let parseConfiguration = JSON.parse(this.staticConfig);

            const productFamiliesObject = parseConfiguration[Object.keys(parseConfiguration)[0]];
    
            let allItems: any[] = [];
    
            Object.keys(productFamiliesObject).forEach(function(key) {
                const productGroupObject = productFamiliesObject[key].items;
    
                let arrayOfOptions: string[] = [];
                Object.keys(productGroupObject).forEach(function(subKey) {
                    arrayOfOptions.push(productGroupObject[subKey]);
                });
    
                let displayConfigItem = {
                    label: key,
                    options: arrayOfOptions.map((groupName: string) => { return { label: groupName, id: groupName }; })
                };
    
                allItems.push(displayConfigItem);
            });

            this.displayConfig.loading = false;
            this.displayConfig.options = allItems;
    
            this.forceUpdate();
        } else {
            this.displayConfig.errorText = 'Error Text';
        }
    }

    componentDidMount = async() => {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        this.displayConfig.loading = true;

        this.experimentServiceAPI.getStaticConfigurationData(this.props.realm!.toString())
            .then((response: string) => { this.staticConfig = response; this.getSuccess = true; })
            .catch((error: any) => console.log(error))
            .finally(() => this.handleGetResults());
    }

    getPayloadValue = (): LambdaModel.BusinessGroupDto => {
        return {
            businessGroup: this.state.displayValue,
            primary: true
        };
    };

    setValueFromPayload = async(businessGroupDto: LambdaModel.BusinessGroupDto) => {
        this.setValue({ id: businessGroupDto.businessGroup, label: businessGroupDto.businessGroup });
    }
}