import React, { FunctionComponent } from 'react';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';

export interface ListDisplayItem {
    id: string;
    primaryText: string;
    secondaryText?: string;
    icon?: JSX.Element;
} 

export interface ListDisplayProps {
    items: ListDisplayItem[];
}

export const ListDisplay: FunctionComponent<ListDisplayProps> = (props: ListDisplayProps) => (
    <List style={{ width: '100%', maxWidth: 500 }}>
        {props.items.map((item: ListDisplayItem) => (
            <div key={item.id}>
                <ListItem>
                    <ListItemText
                        primary={item.primaryText}
                        secondary={item.secondaryText}
                        primaryTypographyProps={{ component: 'h4' }}
                        secondaryTypographyProps={{ component: 'h5' }}
                    />
                </ListItem>
                <Divider component="li" />
            </div>

        ))}
    </List>
);