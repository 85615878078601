import { MetadataAttribute } from '../../enums/CommonTypes';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { TagField, TagFieldConfig } from '../fields/TagField';
import * as LambdaModel from '../../api/experiment-service/lambda-model-types';
import { TokenGroup } from '@amzn/awsui-components-react/polaris';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { Realm } from '../../api/api-constants';
import { AttributeLabels } from '../../constants/display/string-constants';

export class Observers extends TagField {
    protected displayConfig: TagFieldConfig;
    private experimentServiceAPI: LemsApiHandler;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const realm = props.realm ? props.realm : Realm.NA;
        this.experimentServiceAPI = new ApiHandler(realm);

        this.displayConfig = {
            label: AttributeLabels.OBSERVERS,
            editable: false,
            value: [],
            inputProps: {
                className: 'react-tagsinput-input',
                placeholder: 'Ex: jeff'
            },
            hintText: 'Press Tab or Enter to add multiple observers',
            touched: false,
            onChange: (event: any) => this.onChangeEvent(event, MetadataAttribute.OBSERVERS),
        };

        this.addItemFieldConfig = {
            editable: false,
            value: '',
            type: 'text',
            placeholder: 'Add Observer',
            touched: false,
            onChange: (event) => this.onAddItemChangeEvent(event),
            onKeydown: (event) => this.onAddToken(event)
        };

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            validity: true,
            displayTokens: []
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
            const displayTokens: TokenGroup.Item[] = this.props.initialValue.map((observerDto: LambdaModel.ObserverDto) => {
                return {
                    label: observerDto.alias,
                    dismissLabel: `Remove ${observerDto.alias}` 
                };
            });
    
            this.setState({ displayTokens });
        }
    }

    getPayloadValue = (): LambdaModel.ObserverDto[] => {
        const observers: LambdaModel.ObserverDto[] = [];
        this.getTags().forEach((observer) => {
            if (observer.trim()) {
                observers.push({
                    alias: observer.trim()
                });
            }
        });

        return observers;
    };

    setValueFromPayload = async(observers: LambdaModel.ObserverDto[]) => {
        const selectedTags: string[] = [];
        observers.forEach((observerDto) => {
            selectedTags.push(observerDto.alias);
        });

        await this.setValue(selectedTags);
    }

    addItemToBackend = async(newValue: string): Promise<boolean> => {
        return await this.experimentServiceAPI.addObserver(this.props.experimentId!, { alias: newValue });
    }

    removeItemFromBackend = async(value: string) => {
        return await this.experimentServiceAPI.removeObserver(this.props.experimentId!, { alias: value });
    }
}