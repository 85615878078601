import React from 'react';
import { Button, Icon } from '@amzn/awsui-components-react/polaris';
import { encryptCustomerId } from '@amzn/amazon-id';
import { ProgressBar } from '../../common/ProgressBar';
import * as LambdaModel from '../../api/experiment-service/lambda-model-types';
import { ProductSelectionAttribute } from '../../enums/CommonTypes';
import { convertCSVToJSON } from '../../utils/file-convertor';
import * as StringUtils from '../../utils/array-utils';
import { FileField, FileFieldConfig } from '../fields/FileField';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { AttributeLabels } from '../../constants/display/string-constants';
import { FileValidationRules } from '../../validation/validation-rules';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { Realm } from '../../api/api-constants';
import { MARKETPLACE_MAP } from '../../constants/experiment/marketplace-map';
import { LimestoneExperimentSelection } from '../../interfaces/LimestoneExperiment';
import { DisplayAttribute } from '../../interfaces/DisplayAttribute';
import { ExperimentStatusType } from '../../enums/ExperimentStatus';
import { OfferRegionalizationStatusType } from '../../enums/OfferStatusTypes';

export class ProductSelectionFile extends FileField {
    protected displayConfig: FileFieldConfig;
    protected validationRules: FileValidationRules;
    private experimentServiceAPI: LemsApiHandler;
    private offers?: LambdaModel.ExperimentOfferDto[];

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const realm = props.realm ? props.realm : Realm.NA;
        this.experimentServiceAPI = new ApiHandler(realm);

        this.validationRules = {
            required: true,
            allowedOptions: ['text/csv', 'application/vnd.ms-excel'],
            maxSizeInBytes: 600000,
            duplicateCheck: true,
            emptyCheck: true
        };

        const editable = this.props.experimentStatus === ExperimentStatusType.VALIDATION_FAILED || this.props.experimentStatus === ExperimentStatusType.REJECTED ? true : false;
            
        this.displayConfig = {
            label: AttributeLabels.OFFERS_FILE,
            editable,
            touched: false,
            value: []
        };

        this.fileConfig = {
            id: 'custom-file',
            custom: true,
            isInvalid: false,
            label: 'Upload Selection',
            onChange: (event: CustomEvent) => this.onChangeEvent(event, ProductSelectionAttribute.OFFERS_FILE)
        };

        this.summaryDisplay = null;

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            editInProgress: false,
            validity: false
        };
    }

    componentDidMount = async() => {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        if (this.props.experimentId) {
            const marketplace = MARKETPLACE_MAP[this.props.marketplaceId!];
            const obfuscatedMarketplaceId = encryptCustomerId(marketplace);
    
            this.offers = await this.experimentServiceAPI.getAllOffersInExperiment(this.props.experimentId!, obfuscatedMarketplaceId);
        }

        this.forceUpdate();
    }

    setValue = async(newValue: File) => {
        const fileContent = await this.extractContentFromFile(newValue);
        const uniqueValues = StringUtils.getUniqueValues(fileContent);
        const { isValid, errorText } = this.validateFile(newValue, fileContent, this.validationRules);

        const fileName = newValue.name;

        this.displayConfig = {
            ...this.displayConfig,
            touched: true,
            value: uniqueValues,
            errorText
        };

        this.fileConfig = {
            ...this.fileConfig,
            label: fileName,
            isInvalid: !isValid
        };

        this.summaryDisplay = fileName && isValid ? (
            <div style={{ marginTop: '5px' }}>
                <span className="awsui-util-status-positive">
                    <Icon name="status-positive" /> {`File ${fileName} is now available`}
                    <div style={{ marginTop: '5px' }}>{`Size: ${newValue.size}B`}</div>
                    <div>{`ASIN Count: ${uniqueValues.length}`}</div>
                </span>
            </div>
        ) : null;

        await new Promise((resolve) => this.setState({ displayValue: fileName, validity: isValid }, () => resolve(newValue)));
    }

    setValueFromPayload = async(newContent: string[]) => {
        const fileName = this.getDisplayValue() ? this.getDisplayValue() : 'selection_file.csv';
        const file = new File(newContent.map((line) => line + '\n'), fileName, { type: 'text/csv' });
        this.setValue(file);
    }

    validateFile = (value: File, fileContent: any, validationRules: FileValidationRules) => {
        let isValid: boolean = true;
        const errors: string[] = [];

        if (validationRules) {
            if (validationRules.required && !value) {
                isValid = false;
                errors.push('Field is required');
            }

            if (validationRules.allowedOptions && value && !validationRules.allowedOptions.includes(value.type)) {
                isValid = false;
                errors.push('This is not a valid file type');
            }

            if (validationRules.maxSizeInBytes && value && value.size > validationRules.maxSizeInBytes) {
                isValid = false;
                errors.push('Max File Upload Size is ' + validationRules.maxSizeInBytes / 1000 + ' KB');
            }

            if (validationRules.duplicateCheck && fileContent) {
                const duplicateValues = StringUtils.getDuplicates(fileContent);
                if (duplicateValues.size > 0) {
                    isValid = false;
                    errors.push(`${duplicateValues.size} duplicate ASIN(s) present in the file`);
                }
            }
        }

        const errorText = errors.length !== 0 ? errors.join(' and ') : null;

        return { isValid, errorText };
    }

    extractContentFromFile = async(file: File): Promise<string[]> => {
        const asinsArr = await convertCSVToJSON(file);
        return asinsArr.map((asin: string[]) => asin[0].trim()).filter((asin: string) => asin && asin !== '');
    };

    getPayloadValue = () => this.displayConfig.value;

    downloadProductSelection = async(): Promise<void> => {
        const rows = ['ASIN,Regionalization Status,Validation Status,Validation Failed Reasons'];
        this.offers!.forEach((offer: LambdaModel.ExperimentOfferDto) => {
            const offerValidationFailedReasons = offer.offerValidationFailedReasons ? offer.offerValidationFailedReasons.join(' and ') : '';
            rows.push(`${offer.asin},${offer.offerRegionalizationStatus},${offer.offerValidationStatus},${offerValidationFailedReasons}`);
        });

        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(rows.join('\n')));
        element.setAttribute('download', `${this.props.experimentId!}-product-selection`);
      
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
        document.body.removeChild(element);
    }

    refreshProgressBar = async() => {
        if (this.props.experimentId) {
            const marketplace = MARKETPLACE_MAP[this.props.marketplaceId!];
            const obfuscatedMarketplaceId = encryptCustomerId(marketplace);
    
            this.offers = await this.experimentServiceAPI.getAllOffersInExperiment(this.props.experimentId!, obfuscatedMarketplaceId);
        }

        this.forceUpdate();
    }

    renderSelectionSummary = (): JSX.Element => {
        const regionalized = this.offers ? (this.offers.filter((offer) => 
            offer.offerRegionalizationStatus === OfferRegionalizationStatusType.REGIONALIZED).length) : 0;
        const total = this.offers ? this.offers!.length : 0;
        return (<ProgressBar label={'Regionalization Progress'} current={regionalized} total={total} refreshHandler={this.refreshProgressBar}/>);
    }

    renderViewMode = () => (
        <div>
            {this.renderSelectionSummary()}
            <Button loading={this.state.editInProgress!} onClick={() => this.downloadProductSelection()}>
                Download Selection
            </Button>
            <Button disabled={!this.displayConfig.editable} onClick={() => this.setState({ displayMode: DisplayMode.EDIT })}>
                Edit Selection
            </Button>
        </div>
    );

    editSubmitButtonClicked = () => {
        this.setState({ editInProgress: true });
        const productSelection: LimestoneExperimentSelection = {
            offersFile: new DisplayAttribute('Product Selection', true, this.getPayloadValue())
        };

        const marketplace = MARKETPLACE_MAP[this.props.marketplaceId!];
        const obfuscatedMarketplaceId = encryptCustomerId(marketplace);

        this.experimentServiceAPI.uploadProductSelection(productSelection, this.props.experimentId!, obfuscatedMarketplaceId)
            .finally(() => this.setState({ editInProgress: false, displayMode: DisplayMode.VIEW }));
    }
}