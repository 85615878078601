import { CDC_API_ENDPOINTS } from '../api-endpoints';
import * as LambdaModelTypes from '../lambda-model-types';
import { ApiHandler } from '../../api-handler';
import { CdcApiHandler } from './cdc-api-handler';
import { Realm } from '../../api-constants';

export default class CdcApiHandlerImpl extends ApiHandler implements CdcApiHandler {
    public constructor(realm: Realm) {
        super(realm, CDC_API_ENDPOINTS);
    }

    public getTransactionalMetrics = async(experimentId: string, date: string): Promise<LambdaModelTypes.GetTransactionalMetricsResponse> => {
        const url = `getWeeklyTransactionalMetric/${experimentId}/${date}`;
        const response: LambdaModelTypes.GetTransactionalMetricsResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response;
    }

    public getDownstreamMetrics = async(experimentId: string): Promise<LambdaModelTypes.GetDownstreamMetricsResponse> => {
        const url = `getDownstreamMetric/${experimentId}`;
        const response: LambdaModelTypes.GetDownstreamMetricsResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response;
    }
}