import React, { FunctionComponent, Fragment, useContext, useState, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Auth, Hub, Cache } from 'aws-amplify';
import { AppLayout, Flashbar } from '@amzn/awsui-components-react/polaris';
import { WebsiteHeader } from './WebsiteHeader';
import { Page } from '../interfaces/Page';
import { basicPages, adminPages } from '../pages';
import { Sidebar } from '../layout/Sidebar';
import { Footer } from './Footer';
import { ErrorBoundary } from '../common/ErrorBoundary';
import { UnauthenticatedPage } from '../pages/ErrorPages/UnauthenticatedPage';
import RealmContext from '../context/RealmContext';
import UserContext from '../context/UserContext';
import { INotifications } from '../pages/CreateExperiment/CreateWizardTypes';
import { isEmpty } from 'lodash';
import { PageProps } from '../interfaces/IProps';
import { FlashbarMessageDefinition } from '../constants/display/flashbar-messages';
import { UserAttributes } from '../interfaces/UserAttributes';
import { UserAttributeLabels } from '../constants/display/string-constants';
import { Portal } from '../api/api-constants';
import { createExperimentPage } from '../pages/index';

export const renderPage = (page: Page, pageProps: PageProps, notifications: JSX.Element|null) =>
    (<Fragment>
        <ErrorBoundary>
            <WebsiteHeader testId={`${page.testId}-website-header`}
                page={page}
                username={pageProps.userAttributes!.name}
                isAdminPortal={pageProps.portal === Portal.ADMIN}/>
        </ErrorBoundary>

        <ErrorBoundary>
            {(page.path === createExperimentPage.path && !pageProps.userAttributes!.isCreator) || (page.isAdminPage && !pageProps.userAttributes!.isAdmin) ?
                (<UnauthenticatedPage />) :
                (<AppLayout
                    className='awsui-util-no-gutters'
                    content={
                        <ErrorBoundary>
                            {page.header}
                            {React.cloneElement(page.content, pageProps)}
                        </ErrorBoundary>
                    }
                    contentType={page.contentType}
                    notifications={notifications}
                    navigation={<Sidebar testId={page.testId} activeHref={page.path} isAdminPortal={pageProps.portal === Portal.ADMIN}/>}
                    navigationOpen={true}
                    toolsHide={true} />)
            }
        </ErrorBoundary>
    </Fragment>
    );

const App: FunctionComponent = () => {
    const [userAttributes, updateUserAttributes]: [UserAttributes, Function] = useState({
        name: 'Undefined',
        isAdmin: false,
        isCreator: false,
        isDev: false
    });

    const getUserAttributes = async (): Promise<UserAttributes> => {
        const session = await Auth.currentSession();
        const givenName = session.getIdToken().payload[UserAttributeLabels.GIVEN_NAME];
        const familyName = session.getIdToken().payload[UserAttributeLabels.LAST_NAME];
        const userRoles: string = session.getIdToken().payload[UserAttributeLabels.USER_ROLES];

        return {
            name: givenName + ' ' + familyName,
            isAdmin: userRoles.includes(UserAttributeLabels.ADMIN_ROLE),
            isCreator: userRoles.includes(UserAttributeLabels.CREATOR_ROLE),
            isDev: userRoles.includes(UserAttributeLabels.DEV_ROLE)
        };
    };

    useMemo(() => {
        (async () => {
            try {
                const userAttributes = await getUserAttributes();
                updateUserAttributes(userAttributes);
            } catch {
                console.error('There was an error in getting user information');
            }
        })();
    }, []);

    const { realm } = useContext(RealmContext);
    const { portal } = useContext(UserContext);
    const [notifications, updateNotifications]: [INotifications, Function] = useState({});

    Hub.listen('auth', (data) => {
        if (data.payload.event === 'signedIn' && Cache.getItem('location') !== null) {
            const location = Cache.getItem('location');
            Cache.removeItem('location');
            window.open(location, '_self');
        }
    });

    const setNotification = (message: FlashbarMessageDefinition, responseDetails?: string) => {
        message.dismiss = () => dismissNotification(message.id);

        const additionalContent = message.baseContent.length ? [message.baseContent, responseDetails].join(': ') : responseDetails;
        message.content = responseDetails ? additionalContent : message.baseContent;

        const updatedNotificationState = { ...notifications };
        updatedNotificationState[message.id] = message;
        updateNotifications(updatedNotificationState);
    };

    const dismissNotification = (id: string) => {
        const updatedNotificationState = { ...notifications };
        delete updatedNotificationState[id];
        updateNotifications(updatedNotificationState);
    };

    const renderNotifications = (): JSX.Element|null => {
        if (isEmpty(notifications)) {
            return null;
        }
        
        return <Flashbar items={Object.values(notifications)} />;
    };

    const pagesToRender = (portal === Portal.ADMIN) ? adminPages : basicPages;

    return (<div className='awsui'>
        <Fragment>
            <Switch>
                {pagesToRender.map((page, index) => (
                    <Route key={index} exact={page.matchExactPath} path={page.path} render={() => renderPage(page, { realm, portal, setNotification, userAttributes }, renderNotifications() )} />
                ))}
            </Switch>

            <ErrorBoundary>
                <Footer/>
            </ErrorBoundary>
        </Fragment>
    </div>);
};

export default App;
