import React, { FunctionComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import { PageSection } from '../../layout/PageSection';
import { MessageBox, MessageType } from '../../common/MessageBox';

export const UnauthenticatedPage: FunctionComponent = () => (
    <PageSection testId={'unauthenticated'}>
        <MessageBox testId={'unauthenticatedpage'} type={MessageType.ERROR}>
            <Typography variant='h5'>Hello!</Typography>
            <Typography variant='h6'>You are not Authenticated to visit this website</Typography>
            <Typography variant='h6'>If you feel this is a mistake, cut us a SIM at https://tiny.amazon.com/785yvnus/tcorpamazissu</Typography>
            <Typography variant='body2'>Error 403</Typography>
        </MessageBox>
    </PageSection>
);
