import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

export enum ExperimentStatusType {
    AWAITING_INPUTS = 'AWAITING_INPUTS',
    RUNNING_SIMULATION = 'RUNNING_SIMULATION',
    SIMULATION_COMPLETE = 'SIMULATION_COMPLETE',
    SUBMIT_FOR_APPROVALS = 'SUBMIT_FOR_APPROVALS',
    AWAITING_USER_APPROVAL = 'AWAITING_USER_APPROVAL',
    REGION_SETUP_IN_PROGRESS = 'REGION_SETUP_IN_PROGRESS',
    AWAITING_APPROVALS = 'AWAITING_APPROVALS',
    REJECTED = 'REJECTED',
    APPROVED = 'APPROVED',
    UPDATE_EXPERIMENT_START_DATE = 'UPDATE_EXPERIMENT_START_DATE',
    SETUP_IN_PROGRESS = 'SETUP_IN_PROGRESS',
    VALIDATION_FAILED = 'VALIDATION_FAILED',
    VALIDATION_COMPLETE = 'VALIDATION_COMPLETE',
    VALIDATION_IN_PROGRESS = 'VALIDATION_IN_PROGRESS',
    RUNNING = 'RUNNING',
    STARTING_EXPERIMENT = 'STARTING_EXPERIMENT',
    ENDING_EXPERIMENT = 'ENDING_EXPERIMENT',
    COMPLETE = 'COMPLETE',
}

type StatusTypeMap = {
    [name in ExperimentStatusType]: JSX.Element;
};

export const ExperimentStatus: StatusTypeMap = {
    AWAITING_INPUTS: <span className="awsui-util-status-info"><HourglassEmptyIcon fontSize='large'/> Awaiting Inputs</span>,
    RUNNING_SIMULATION: <span className="awsui-util-status-info"><HourglassEmptyIcon fontSize='large'/>Running Simulation</span>,
    SIMULATION_COMPLETE: <span className="awsui-util-status-positive"><CheckCircleIcon fontSize='large'/> Simulation Complete</span>,
    SUBMIT_FOR_APPROVALS: <span className="awsui-util-status-info"><HourglassEmptyIcon fontSize='large'/>Submit for approvals</span>,
    AWAITING_APPROVALS: <span className="awsui-util-status-info"><HourglassEmptyIcon fontSize='large'/>Awaiting approvals</span>,
    AWAITING_USER_APPROVAL: <span className="awsui-util-status-info"><HourglassEmptyIcon fontSize='large'/>Awaiting User Approval</span>,
    REGION_SETUP_IN_PROGRESS: <span className="awsui-util-status-info"><DoubleArrowIcon fontSize='large'/> Region Setup In Progress</span>,
    REJECTED: <span className="awsui-util-status-negative"><WarningIcon fontSize='large'/> Rejected</span>,
    APPROVED: <span className="awsui-util-status-positive"><CheckCircleIcon fontSize='large'/> Approved</span>,
    UPDATE_EXPERIMENT_START_DATE: <span className="awsui-util-status-info"><AccessTimeIcon fontSize='large'/>Update Experiment Start Date</span>,
    SETUP_IN_PROGRESS: <span className="awsui-util-status-info"><DoubleArrowIcon fontSize='large'/> Setup In Progress</span>,
    VALIDATION_COMPLETE: <span className="awsui-util-status-positive"><CheckCircleIcon fontSize='large'/> Validation Complete</span>,
    VALIDATION_IN_PROGRESS: <span className="awsui-util-status-info"><DoubleArrowIcon fontSize='large'/> Validation In Progress</span>,
    VALIDATION_FAILED: <span className="awsui-util-status-negative"><WarningIcon fontSize='large'/> Validation Failed</span>,
    STARTING_EXPERIMENT: <span className="awsui-util-status-info"><DoubleArrowIcon fontSize='large'/> Starting Experiment</span>,
    ENDING_EXPERIMENT: <span className="awsui-util-status-info"><DoubleArrowIcon fontSize='large'/> Ending Experiment</span>,
    RUNNING: <span className="awsui-util-status-positive"><AccessTimeIcon fontSize='large'/> Running</span>,
    COMPLETE: <span className="awsui-util-status-positive"><CheckCircleIcon fontSize='large'/> Complete</span>,
};
