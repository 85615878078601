export enum TableDimension {
    AGGREGATED_DIMENSION = 'AGGREGATED_DIMENSION',
    METRIC_DIMENSION = 'METRIC_DIMENSION'
}

export enum MetricDataType {
    METRIC_DIMENSION = 'METRIC_DIMENSION',
    OBSERVED_DATA = 'OBSERVED_DATA',
    RMSE_RATIO = 'RMSE_RATIO',
    P_VALUE_AUDIT = 'P_VALUE_AUDIT',
    ESTIMATED_BASE = 'ESTIMATED_BASE',
    ABSOLUTE_LIFT = 'ABSOLUTE_LIFT',
    PERCENT_LIFT = 'PERCENT_LIFT',
    LIFT_PER_USER = 'LIFT_PER_USER',
    P_VALUE = 'P_VALUE'
}

export interface TransactionalMetricsRow {
    dimensionValues: string[];
    metricValues: number[];
}

export interface TableSchema {
    dimensionColumnIndex: Object;
    metricColumnIndex: Object;
}

export interface MetricsTable {
    schema: TableSchema;
    rows: TransactionalMetricsRow[];
}

export interface GetTransactionalMetricsResponse {
    totalTreatmentCustomers: number;
    totalControlCustomers: number;
    table: MetricsTable;
}

export interface GetDownstreamMetricsResponse {
    table: MetricsTable;
}