import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { PageSection } from '../../layout/PageSection';
import { IProps } from '../../interfaces/IProps';

interface Announcement {
    title: string;
    content: string;
}

const useStyles = makeStyles(({
    header: {
        margin: '5px 15px',
        padding: '10px 10px 0 10px'
    },
    newsItem: {
        margin: '15px 15px',
        padding: '0 0 10px 10px'
    }
}));

export const AnnouncementSection: FunctionComponent<IProps> = ({ testId }) => {
    const classes = useStyles();
    const initialAnnouncementState: Announcement[] = [
        { title: 'ReSES US Launch', content: 'ReSES launched its first Experiment succesfully on 1/9/2020' },
        { title: 'ReSES OAM Launch', content: 'ReSES launched its second Experiment succesfully on 2/22/2021' },
        { title: 'ReSES EU Launch', content: 'ReSES is set to launch its first experiment in EU in 2021' }
    ];

    const [announcements,] = useState(initialAnnouncementState);

    return (
        <PageSection testId={`${testId}-annoucements`}>
            <Typography variant='h1'><div className={classes.header}>Announcements</div></Typography>
            {announcements.map((announcement, index) => (
                <div key={index} className={classes.newsItem}>
                    <Typography variant='h4'>{announcement.title}</Typography>
                    <Typography variant='h5'><i>{announcement.content}</i></Typography>
                </div>
            ))}
        </PageSection>
    );
};