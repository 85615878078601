/* istanbul ignore file */

import { Flashbar } from '@amzn/awsui-components-react/polaris';

export enum CreateWizardSteps {
    METADATA = 'metadata',
    PRODUCT_SELECTION = 'productSelection',
    REGION_SELECTION = 'regionSelection',
    SUMMARY = 'summary'
}

export enum StepId {
    METADATA = 0,
    PRODUCT_SELECTION = 1,
    REGION_SELECTION = 2,
    SUMMARY = 3
}

export interface INotifications {
    [id: string]: Flashbar.MessageDefinition
}

export const wizardI18N = {
    stepNumberLabel: (stepNumber: number) => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber: number, stepsCount: number) => `Step ${stepNumber} of ${stepsCount}`,
    cancelButton: 'Cancel',
    previousButton: 'Previous',
    nextButton: 'Next',
    submitButton: 'Submit',
    optional: 'optional'
};