import React, { Component } from 'react';
import { FormSection, Button, ColumnLayout } from '@amzn/awsui-components-react/polaris/';
import { TextField } from '@material-ui/core';
import { PageProps } from '../../interfaces/IProps';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import UserContext from '../../context/UserContext';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import * as NOTIFICATION_MESSAGES from '../../constants/display/flashbar-messages';
import { INotifications } from '../CreateExperiment/CreateWizardTypes';
import { StaticConfigSubmitModalAttributes } from '../../constants/display/modal-constants';
import { IButtonHandler } from '../../interfaces/IButtonHandler';
import { UserInputModal } from '../../common/UserInputModal';

export interface StaticConfigDataPageState {
    configurations: string;
    configurationsLoading: boolean;
    getSuccess: boolean;
    updateSuccess: boolean;
    notifications: INotifications;
    showSubmitModal: boolean;
}

export class UpdateStaticConfigData extends Component<PageProps, StaticConfigDataPageState> {
    /**
     * Experiment Service handler instance which provides api to get the experiment data from the backend
     */
     private experimentServiceAPI: LemsApiHandler;
     private readonly submitModalHandler: IButtonHandler;

    static contextType = UserContext;

    constructor(props: PageProps) {
        super(props);
        this.state = {
            configurationsLoading: true,
            configurations: '',
            getSuccess: false,
            updateSuccess: false,
            notifications: {},
            showSubmitModal: false
        };

        this.experimentServiceAPI = new ApiHandler(props.realm);

        this.submitModalHandler = {
            dismiss: () => this.setState({ showSubmitModal: false }),
            submit: () => this.handleSubmitModalClicked()
        };
    }

    private placeholderText = 'Loading configurations...';

    /* istanbul ignore next */
    handleTextChange = (event: any) => {
        this.setState({ configurations: event.target.value });
    }

    handleSubmitButtonClicked = () => {
        this.setState({ showSubmitModal: true });
    }

    /* istanbul ignore next */
    handleSubmitModalClicked = () => {
        this.setState({ showSubmitModal: false });
        // No .finally() because the bake time of deployment is 10 minutes and will not finish within Coral timeout limit 
        this.experimentServiceAPI.updateStaticConfigurationData(this.state.configurations, this.props.realm)
            .then(() => this.props.setNotification!(NOTIFICATION_MESSAGES.updateStaticConfigurationData.SUCCESS))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.updateStaticConfigurationData.FAIL!));
    }

    /* istanbul ignore next */
    componentDidMount = async() => await this.fetchConfigurations();

    /* istanbul ignore next */
    fetchConfigurations = async() => {
        this.experimentServiceAPI.getStaticConfigurationData(this.props.realm)
            .then((response: string) => this.setState({ configurations: JSON.stringify(JSON.parse(response), null, 2), getSuccess: true }))
            .catch((error: any) => {
                handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getStaticConfigurationData.FAIL!);
                this.placeholderText = 'Failed to retrieve configurations';
            })
            .finally(() => this.setState({ configurationsLoading: false }));
    }

    render() {
        return (<div>
            <UserInputModal 
                visible={this.state.showSubmitModal}
                buttonHandlers={this.submitModalHandler}
                {...StaticConfigSubmitModalAttributes}
            />

            <div>
                <FormSection header={'Edit Static Configuration Data'}>
                    <ColumnLayout>
                        <div data-awsui-column-layout-root='true'>
                            <TextField 
                                InputProps={{ style:{ fontSize: 16 } }} 
                                placeholder={this.placeholderText}
                                id="standard-multiline-flexible"
                                disabled={!this.state.getSuccess}
                                helperText={'Make edits in the above text box, and then press submit to update the configuration data.'} 
                                value={this.state.configurations}
                                onChange={this.handleTextChange}
                                multiline />
                            <Button data-testid={'submit-button'} onClick={this.handleSubmitButtonClicked}>Submit</Button>
                        </div>
                    </ColumnLayout>
                </FormSection>
            </div>
        </div>);
    }
}
