import { Approver } from '../../../interfaces/Approver';
import { ExperimentApproverDto } from '../lambda-model-types';
import { ApprovalStatus } from '../../../enums/ApprovalStatusType';
import { ApproverRole } from '../../../enums/ApproverRoleType';

export const convertApproverDtoToApprover = (approverDto: ExperimentApproverDto): Approver => {
    return {
        alias: approverDto.alias,
        status: (ApprovalStatus as any)[approverDto.status],
        role: (ApproverRole as any)[approverDto.type],
        rejectionReason: approverDto.rejectReason
    };
};