import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

export enum ApprovalStatusType {
    NOT_STARTED = 'NOT_STARTED',
    PENDING_RESPONSE = 'PENDING_RESPONSE',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED'
}

type StatusTypeMap = {
    [name in ApprovalStatusType]: JSX.Element;
};

export const ApprovalStatus: StatusTypeMap = {
    NOT_STARTED: <span className="awsui-util-status-info"><AccessTimeIcon fontSize='large'/> Not Started</span>,
    PENDING_RESPONSE: <span className="awsui-util-status-info"><HourglassEmptyIcon fontSize='large'/> Pending Response</span>,
    APPROVED: <span className="awsui-util-status-positive"><CheckCircleIcon fontSize='large'/> Approved</span>,
    REJECTED: <span className="awsui-util-status-negative"><WarningIcon fontSize='large'/> Rejected</span>
};
