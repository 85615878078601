import React from 'react';
import { Multiselect, Select } from '@amzn/awsui-components-react/polaris';
import { ExperimentAttribute, ExperimentAttributeConfig } from '../ExperimentAttribute';
import { IValidationRules } from '../../validation/validation-rules';

export interface MultiSelectFieldConfig extends ExperimentAttributeConfig, Multiselect.Props {}

/**
 * Field to allow use select multiple options from a given list of options.
 * Reference: https://polaris.a2z.com/components/awsui-multiselect/?example=default
 */
export abstract class MultiSelectField extends ExperimentAttribute {
    protected displayConfig!: MultiSelectFieldConfig;

    parseValueFromEvent = (event: CustomEvent<Select.MultiselectChangeDetail>): Select.Option[] => event.detail.selectedOptions;

    getSelectedOptions = (): Select.Option[] => this.displayConfig.selectedOptions!;

    validate = (options: any[], validationRules: IValidationRules) => {
        let isValid = true;
        const errors: string[] = [];

        if (validationRules) {
            if (validationRules.required && (!options || options.length === 0)) {
                isValid = false;
                errors.push('Field is required');
            }

            if (validationRules.maxOptions && (options && options.length > validationRules.maxOptions)) {
                isValid = false;
                errors.push('Exceeds max number of allowed options');
            }
        }

        const errorText = errors.length !== 0 ? errors.join(' and ') : null;
        return { isValid, errorText };
    }
    
    setValue = async(newValue: Select.Option[]) => {
        const newDisplayValue = newValue ? newValue.map((option: Select.Option) => option.label).join(', ') : '';
        const { isValid, errorText } = this.validate(newValue, this.validationRules);

        this.displayConfig = {
            ...this.displayConfig,
            touched: true,
            selectedOptions: newValue,
            invalid: this.displayConfig.touched && !isValid,
            errorText,
        };
        
        await new Promise((resolve) => this.setState({ displayValue: newDisplayValue, validity: isValid }, () => resolve(newValue)));
    }

    getPolarisElement = () => <Multiselect {...this.displayConfig}/>
}