import React from 'react';
import { ExternalLinks } from './external-links';

interface IModalAttributes {
    testId: string;
    header: string;
    content?: JSX.Element;
}

export const WizardCancelModalAttributes: IModalAttributes = {
    testId: 'wizard-cancel-modal',
    header: 'All your changes will be lost',
};

export const WizardSubmitModalAttributes: IModalAttributes = {
    testId: 'wizard-submit-modal',
    header: 'Confim Submit',
    content: (
        <div>
            <h3> CAUTION: Submission of this form will create a Limestone Experiment Entity.</h3>
            <p>After submitting this form, you cannot change your Marketplace, Product Family and Business Groups. 
                You may still however edit other fields by navigating to the Experiment Detail Page from the MyExperimentsPage. </p> 
                
            <p>This submission will not start the experiment. If your submitted form is approved, you can start your experiment
            from the Experiment Detail Page.</p>
        </div>
    )
};

export const StartExperimentModalAttributes: IModalAttributes = {
    testId: 'start-experiment-modal',
    header: 'Confirm that you want to start the experiment.',
    content: (
        <span>
            <h3> CAUTION: This will start the regionalization process. </h3>
            <p> The time taken for regionalization process to complete will be upto 10 mins depending on the size of your selection.
            You can view our <a href={ExternalLinks.GUIDE.href}>Onboarding Wiki</a> to check out alternate methods to verify whether your selection has been regionalized.
            </p>
            <p> You can view the status of your experiment by navigating to the MyExperimentsPage.</p>
        </span>
    )
};

export const EndExperimentModalAttributes: IModalAttributes = {
    testId: 'end-experiment-modal',
    header: 'Confirm that you are ending the experiment'
};

export const SubmitBoundariesModalAttributes: IModalAttributes = {
    testId: 'submit-boundaries-modal',
    header: 'Confirm that you want to submit the treatment boundaries'
};

export const ApproveExperimentModalAttributes: IModalAttributes = {
    testId: 'approve-experiment-modal',
    header: 'Confirm that you want to approve the experiment. You cannot undo your decision'
};

export const RejectExperimentModalAttributes: IModalAttributes = {
    testId: 'reject-experiment-modal',
    header: 'Confirm that you want to reject the experiment. You cannot undo your decision'
};

export const StaticConfigSubmitModalAttributes: IModalAttributes = {
    testId: 'submit-static-config-update',
    header: 'Confirm that you want to update the ReSES static configuration data.'
};

export const RegionalizeUnregionalizeAsinSubmitModalAttributes: IModalAttributes = {
    testId: 'regionalize-unregionalize-asin-submit-modal',
    header: 'Confirm that you want to SUBMIT an ASIN request.'
};

export const RegionalizeUnregionalizeAsinExecuteModalAttributes: IModalAttributes = {
    testId: 'regionalize-unregionalize-asin-execute-modal',
    header: 'Confirm that you want to EXECUTE this ASIN request.'
};