import { LimestoneExperiment } from '../../interfaces/LimestoneExperiment';
import React, { Component } from 'react';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import UserContext from '../../context/UserContext';
import { DisplayTable } from '../../common/DisplayTable';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import { PageProps } from '../../interfaces/IProps';
import * as NOTIFICATION_MESSAGES from '../../constants/display/flashbar-messages';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import { getColumnDefinition, columnOptions, pageSizeOptions } from '../../constants/table/my-experiments-definition';
import { TableHeaders } from '../../constants/display/string-constants';

export interface MyExperimentsPageState {
    title: String;
    experiments: LimestoneExperiment[];
    tableLoading: boolean;
}

export class MyExperimentsPage extends Component<PageProps, MyExperimentsPageState> {
    /**
     * Experiment Service handler instance which provides api to get the experiment data from the backend
     */
    private experimentServiceAPI: LemsApiHandler;

    static contextType = UserContext;

    constructor(props: PageProps) {
        super(props);
        this.state = {
            title: 'My Experiments',
            tableLoading: true,
            experiments: [],
        };

        this.experimentServiceAPI = new ApiHandler(props.realm);
    }

    /* istanbul ignore next */
    componentDidUpdate = async(prevProps: PageProps) => {
        if (prevProps.realm !== this.props.realm) {
            await this.fetchExperiments();
        }
    }

    /* istanbul ignore next */
    componentDidMount = async() => await this.fetchExperiments();

    /* istanbul ignore next */
    fetchExperiments = async() => {
        console.log(`Fetching Experiments for ${this.props.realm.toUpperCase()} realm`);
        await this.experimentServiceAPI.getExperimentsByOwner(this.context.username)
            .then((response: LimestoneExperiment[]) => this.setState({ experiments: response }))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentsByOwner.FAIL!))
            .finally(() => this.setState({ tableLoading: false }));
    }

    render() {
        return (
            <div className='awsui-util-container' style={ { padding: '20px' } }>
                <DisplayTable
                    title={<h2>{TableHeaders.MY_EXPERIMENTS} <span className="awsui-util-header-counter">({this.state.experiments.length})</span></h2>}
                    items={this.state.experiments}
                    tableLoading={this.state.tableLoading}
                    columnDefinitions={getColumnDefinition()}
                    columnOptions={columnOptions}
                    pageSizeOptions={pageSizeOptions}
                    paginationEnabled={true}
                    filteringEnabled={true}
                    preferencesEnabled={true}
                />
            </div>
        );
    }
}
