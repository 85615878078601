import React from 'react';
import { Table } from '@amzn/awsui-components-react/polaris';
import TableContentSelector from '@amzn/awsui-components-react/polaris/table-content-selector';
import { BoundarySet } from '../../interfaces/BoundarySet';
import { ClickableBackdrop } from '../../common/ClickableBackdrop';
import { ListDisplay, ListDisplayItem } from '../../common/ListDisplay';

export const getColumnDefinitions = (boundarySetColumns: string[]): Array<Table.ColumnDefinition<BoundarySet>> => {
    const columnOptions: Array<Table.ColumnDefinition<BoundarySet>> = [{
        id: 'Treatment Region',
        header: 'Treatment Region',
        cell: (item: BoundarySet) => {
            const boundarySet: ListDisplayItem[] = Array.from(item.boundaries.keys()).map((boundaryId) => {
                return {
                    id: boundaryId,
                    primaryText: `${boundaryId}: ${item.boundaries.get(boundaryId)}`
                };
            });

            return (<ClickableBackdrop content={<ListDisplay items={boundarySet}/>}/>);
        },
        width: 200
    }];

    boundarySetColumns.forEach((columnName: string) => {
        columnOptions.push({
            id: columnName,
            header: columnName,
            allowLineWrap: false,
            cell: (item: BoundarySet) => <div>{item.attributes.get(columnName)}</div>,
        });
    });

    return columnOptions;
};

export const getColumnOptions = (boundarySetColumns: string[]): Array<TableContentSelector.ContentDescriptionGroup> => {
    const columnOptions: Array<TableContentSelector.ContentDescription> = boundarySetColumns.map((columnName: string) => {
        return {
            id: columnName,
            label: columnName,
            editable: false,
            visible: true
        };
    });

    return [{
        label: 'Simulated Treatment Region',
        options: [
            {
                id: 'Treatment Region',
                label: 'Treatment Region',
                editable: false,
                visible: true
            },
            ...columnOptions
        ]
    }];
};

export const pageSizeOptions = [
    { value: 10, label: '10 options' },
    { value: 15, label: '15 options' },
    { value: 20, label: '20 options' }];
