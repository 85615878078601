import React, { Component } from 'react';
import { Button, ColumnLayout, Icon, Spinner, ExpandableSection, Flashbar } from '@amzn/awsui-components-react/polaris';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { StepDetailsQueryParams } from '../../api/api-constants';
import { LimestoneExperiment, LimestoneExperimentBoundaries } from '../../interfaces/LimestoneExperiment';
import { IButtonHandler } from '../../interfaces/IButtonHandler';
import * as NOTIFICATION_MESSAGES from '../../constants/display/flashbar-messages';
import * as FormUtils from '../../utils/form-utils';
import * as UrlUrils from '../../utils/url-utils';
import UserContext from '../../context/UserContext';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import { PageProps } from '../../interfaces/IProps';
import { FormFactory } from '../../factories/FormFactory';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { UserInputModal } from '../../common/UserInputModal';
import { ApproveExperimentModalAttributes, RejectExperimentModalAttributes } from '../../constants/display/modal-constants';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import { Approver } from '../../interfaces/Approver';
import { ApprovalStatusType, ApprovalStatus } from '../../enums/ApprovalStatusType';
import { ApproverRoleType, ApproverRole } from '../../enums/ApproverRoleType';
import { RejectionReason } from '../../form/attributes/RejectionReason';
import { DisplayAttribute } from '../../interfaces/DisplayAttribute';
import { AttributeLabels } from '../../constants/display/string-constants';
import { approversQueue } from '../index';
import { ExperimentStatusType } from '../../enums/ExperimentStatus';

export interface ApproverDetailPageProps extends RouteComponentProps, PageProps {}

export interface ApproverDetailPageState {
    experimentId: string;
    experimentIntegerId: number;
    experiment: LimestoneExperiment;
    downloadButtonLoading: boolean;
    approveButtonDisabled: boolean;
    approveButtonLoading: boolean;
    showApproveModal: boolean;
    rejectButtonDisabled: boolean;
    rejectButtonLoading: boolean;
    showRejectModal: boolean;
    invalidPageStateModalReason: string;
    showInvalidPageStateFlashbar: boolean;
    rejectionReason: DisplayAttribute;
    isRejectModalValid: boolean;
    approverAlias: string;
    approvalType: ApproverRoleType;
    showSpinner: boolean;
}

export class ApproverDetailPage extends Component<ApproverDetailPageProps, ApproverDetailPageState> {
    private readonly buttonHandlers: any;
    private readonly approveModalHandlers: IButtonHandler;
    private readonly rejectModalHandlers: IButtonHandler;

    /** Experiment Service handler instance which provides api to get the experiment data from the backend */
    public experimentServiceAPI: LemsApiHandler;

    /** Factory class which returns the appropriate form. */
    private formFactory: FormFactory;

    private static LIMESTONE_ADMIN_ALIAS = 'limestone-dev';
    private static INVALID_EXPERIMENT_STATUS_REASON = 'This experiment is not in the stage which requires approvals.';
    private static UNAUTHORIZED_APPROVER_REASON = 'You are not authorized to access this page.';
    private static INVALID_EXPERIMENT_ID_REASON = 'The experiment ID provided in the URL does not exist, please enter a valid experiment ID.';

    static contextType = UserContext;

    public constructor(props: any) {
        super(props);

        this.experimentServiceAPI = new ApiHandler(props.realm);
        this.formFactory = new FormFactory();

        this.state = {
            experimentId: '',
            experimentIntegerId: -1,
            showSpinner: true,
            experiment: FormUtils.createEmptyLimestoneExperiment(),
            downloadButtonLoading: false,
            approveButtonDisabled: true,
            approveButtonLoading: false,
            showApproveModal: false,
            rejectButtonDisabled: true,
            rejectButtonLoading: false,
            showRejectModal: false,
            isRejectModalValid: true,
            approverAlias: '',
            invalidPageStateModalReason: ApproverDetailPage.INVALID_EXPERIMENT_ID_REASON,
            showInvalidPageStateFlashbar: true,
            approvalType: ApproverRoleType.BUSINESS,
            rejectionReason: new DisplayAttribute(AttributeLabels.REJECTION_REASON),
        };

        this.experimentServiceAPI = new ApiHandler(props.realm);

        this.buttonHandlers = {
            approveExperiment: () => this.setState({ showApproveModal: true }),
            rejectExperiment: () => this.setState({ showRejectModal: true })
        };

        this.approveModalHandlers = {
            dismiss: () => this.setState({ showApproveModal: false }),
            submit: () => this.submitApproval()
        };

        this.rejectModalHandlers = {
            dismiss: () => this.setState({ showRejectModal: false }),
            submit: () => this.submitRejection()
        };
    }

    componentDidMount = async() => {
        const { experimentId, experimentIntegerId }: StepDetailsQueryParams = UrlUrils.parseStepDetailsFromUrl(new URL(window.location.href));
        this.setState({ showSpinner: true });

        if (experimentId && experimentIntegerId) {
            this.setState({ experimentId, experimentIntegerId });

            const experiment = await this.experimentServiceAPI.readExperiment(experimentId, experimentIntegerId)
                .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.readExperiment.FAIL!));

            if (experiment) {
                if (experiment.currentStatus.currentStatus.payloadValue !== ExperimentStatusType.AWAITING_APPROVALS) {
                    this.setState({ 
                        showSpinner: false,
                        showInvalidPageStateFlashbar: true,
                        invalidPageStateModalReason: ApproverDetailPage.INVALID_EXPERIMENT_STATUS_REASON
                    });
                } else {
                    await this.experimentServiceAPI.getAllExperimentBoundaries(experimentId)
                        .then((response: LimestoneExperimentBoundaries|undefined) => {
                            experiment.regionSelection = response!;
                            this.setState({ approveButtonDisabled: false, rejectButtonDisabled: false });
                        })
                        .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentBoundaries.FAIL!))
                        .finally(() => this.setState({ showSpinner: false, experiment }));

                    await this.experimentServiceAPI.getAllExperimentApprovers(experimentId)
                        .then((approvers: Approver[]) => {
                            if (this.props.userAttributes?.isAdmin && approvers.filter((approver) => this.isApproverValidForApproving(approver, ApproverDetailPage.LIMESTONE_ADMIN_ALIAS)).length > 0) {
                                this.setState({ approverAlias: ApproverDetailPage.LIMESTONE_ADMIN_ALIAS, approvalType: ApproverRoleType.LIMESTONE, showInvalidPageStateFlashbar: false });
                            } else if (approvers.filter((approver) => this.isApproverValidForApproving(approver, this.context.username)).length > 0) {
                                this.setState({ approverAlias: this.context.username, approvalType: ApproverRoleType.BUSINESS, showInvalidPageStateFlashbar: false });
                            } else {
                                this.setState({ showInvalidPageStateFlashbar: true, invalidPageStateModalReason: ApproverDetailPage.UNAUTHORIZED_APPROVER_REASON });
                            }
                        })
                        .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getAllExperimentBoundaries.FAIL!))
                        .finally(() => this.setState({ showSpinner: false }));
                }
            }
        }
    }

    submitApproval = async() => {
        this.setState({ rejectButtonDisabled: true, approveButtonLoading: true, showApproveModal: false });

        await this.experimentServiceAPI.submitApprovalDecision(this.state.experimentId, this.state.approvalType, this.state.approverAlias, ApprovalStatusType.APPROVED)
            .then(() => {
                this.props.setNotification!(NOTIFICATION_MESSAGES.submitApprovalDecision.SUCCESS);
                this.props.history.push(approversQueue.path);
            })
            .catch((error: any) => {
                handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.submitApprovalDecision.FAIL!);
                this.setState({ rejectButtonDisabled: false, approveButtonDisabled: false, approveButtonLoading: false });
            });
    }

    submitRejection = async() => {
        if (this.state.rejectionReason.isValid) {
            this.setState({ rejectButtonLoading: true, showRejectModal: false, approveButtonDisabled: true, isRejectModalValid: true });

            await this.experimentServiceAPI.submitApprovalDecision(this.state.experimentId, this.state.approvalType, this.state.approverAlias, ApprovalStatusType.REJECTED, this.state.rejectionReason.payloadValue)
                .then(() => {
                    this.props.setNotification!(NOTIFICATION_MESSAGES.submitApprovalDecision.SUCCESS);
                    this.props.history.push(approversQueue.path);
                })
                .catch((error: any) => {
                    handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.submitApprovalDecision.FAIL!);
                    this.setState({ rejectButtonDisabled: false, rejectButtonLoading: false, approveButtonDisabled: false });
                });
        } else {
            this.setState({ isRejectModalValid: false });
        }
    }

    updateRejectionReason = async(_fieldId: string, payloadValue: any, displayValue: string, isValid: boolean) => {
        const rejectionReason = this.state.rejectionReason;
        rejectionReason.updateAttributeDetails(isValid, payloadValue, displayValue);
        this.setState({ rejectionReason });
    }

    isApproverValidForApproving = (approver: Approver, expectedAlias: string) => {
        return approver.alias === expectedAlias && approver.status === ApprovalStatus.PENDING_RESPONSE;
    }

    render() {
        const rejectionReasonField = (
            <RejectionReason 
                updateFormState={this.updateRejectionReason}
                initialValue={this.state.rejectionReason.displayValue}
                displayMode={DisplayMode.CREATE}/>
        ); 
        
        const rejectModalErrorMessage = !this.state.isRejectModalValid && (
            <span className="awsui-util-status-negative"><Icon name="status-warning" />Please enter a rejection reason</span>
        );

        const rejectModalContent = (
            <>
                <>{rejectionReasonField}</>
                <>{rejectModalErrorMessage}</>
            </>);

        const actionStripe = (
            <div className="awsui-util-mb-m awsui-util-mt-xs">
                <div className="awsui-util-action-stripe-large">
                    <div className="awsui-util-action-stripe-title">
                        <h1>{`${this.state.experiment.metadata.title.displayValue} (ID: ${this.state.experiment.experimentIntegerId})`}</h1>
                        <h2>{'Approval Type: '}<span>{ApproverRole[this.state.approvalType]}</span></h2>
                    </div>
                </div>
            </div>);

        const modals = (
            <>
                <UserInputModal 
                    visible={this.state.showApproveModal}
                    buttonHandlers={this.approveModalHandlers}
                    {...ApproveExperimentModalAttributes}
                />

                <UserInputModal 
                    visible={this.state.showRejectModal}
                    buttonHandlers={this.rejectModalHandlers}
                    content={rejectModalContent}
                    {...RejectExperimentModalAttributes}
                />
            </>
        );

        let content: JSX.Element;
        if (this.state.showSpinner) {
            content = (<Spinner data-testid={'approver-detail-page-spinner'} size='large' />);
        } else if (this.state.showInvalidPageStateFlashbar) {
            content = <Flashbar data-testid={'invalid-state-flashbar'} items={[{ 
                header: `Unauthorized Request for Experiment Approvals: ${this.state.experiment.metadata.title.displayValue} (ID: ${this.state.experimentIntegerId})`,
                content: this.state.invalidPageStateModalReason,
                type: 'warning' 
            }]}/>;
        } else {
            content = (
                <>
                    {actionStripe}

                    <ExpandableSection header='Experiment Definition' variant='container' expanded={true}>
                        <ColumnLayout columns={4} variant='text-grid'>
                            {this.formFactory.getMetadataForm(undefined, DisplayMode.VIEW, true, this.state.experiment.metadata, this.state.experimentId, this.props.realm)}
                        </ColumnLayout>
                    </ExpandableSection>

                    <ExpandableSection header='Experiment Selection' variant='container' expanded={true}>
                        <ColumnLayout columns={1} variant='text-grid'>
                            {this.formFactory.getProductSelectionForm(
                                undefined,
                                DisplayMode.VIEW,
                                true,
                                this.state.experiment.productSelection,
                                this.state.experimentId,
                                this.state.experiment.metadata.marketplace.displayValue,
                                this.state.experiment.currentStatus.currentStatus.payloadValue)}
                        </ColumnLayout>
                    </ExpandableSection>

                    <ExpandableSection header={'Experiment Boundaries'} variant='container' expanded={true}>
                        <ColumnLayout columns={2} variant='text-grid'>
                            <div data-awsui-column-layout-root='true'>
                                <div style={{ display: 'table' }}>
                                    <div data-testid={'display-wrapper'} style={{ display: 'table-cell' }}>
                                        <div className='awsui-util-label'><strong><u>{this.state.experiment.regionSelection.treatmentBoundaries.displayLabel}</u></strong></div>
                                        <div>{this.state.experiment.regionSelection.treatmentBoundaries.displayValue}</div>
                                    </div>
                                </div>

                                <div style={{ display: 'table' }}>
                                    <div data-testid={'display-wrapper'} style={{ display: 'table-cell' }}>
                                        <div className='awsui-util-label'><strong><u>{this.state.experiment.metadata.regionCreationStatus.displayLabel}</u></strong></div>
                                        <div>{this.state.experiment.metadata.regionCreationStatus.displayValue}</div>
                                    </div>
                                </div>
                            </div>
                        </ColumnLayout>
                    </ExpandableSection>
                    <div className="awsui-util-action-stripe-group">
                        <Button
                            variant='normal'
                            data-testid={'reject-experiment-button'}
                            loading={this.state.rejectButtonLoading}
                            disabled={this.state.rejectButtonDisabled}
                            onClick={this.buttonHandlers.rejectExperiment}
                        >Reject</Button>
                        <Button
                            variant='primary'
                            data-testid={'approve-experiment-button'}
                            loading={this.state.approveButtonLoading}
                            disabled={this.state.approveButtonDisabled}
                            onClick={this.buttonHandlers.approveExperiment}
                        >Approve</Button>
                    </div>
                    
                    {modals}
                </>
            );
        }
        return (
            <div className="awsui-util-container" style={{ padding: 20 }}>
                {content}
            </div>
        );
    }
}

export default withRouter(ApproverDetailPage);
