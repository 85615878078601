import { MetadataAttribute } from '../../enums/CommonTypes';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { InputField, InputFieldConfig } from '../fields/InputField';
import * as LambdaModel from '../../api/experiment-service/lambda-model-types';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { AttributeLabels } from '../../constants/display/string-constants';

export class PrimaryOwner extends InputField {
    protected displayConfig: InputFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true, maxLength: 128 };
        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.PRIMARY_OWNER,
            editable: false,
            type: 'text',
            placeholder: 'Enter amazon alias of Primary Owner (Without a \'@\')',
            touched: false,
            hintText: 'Required *',
            errorText,
            onChange: (event) => this.onChangeEvent(event, MetadataAttribute.PRIMARY_OWNER),
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    getPayloadValue = (): LambdaModel.OwnerDto => {
        return {
            alias: this.state.displayValue,
            primary: true
        };
    };

    setValueFromPayload = async(ownerDto: LambdaModel.OwnerDto) => {
        this.setValue(ownerDto.alias);
    }
}