import React from 'react';
import { Input, Spinner, TokenGroup } from '@amzn/awsui-components-react/polaris';
import TagsInput from 'react-tagsinput';
import { ExperimentAttribute, ExperimentAttributeConfig } from '../ExperimentAttribute';
import { InputFieldConfig } from './InputField';

export interface TagFieldConfig extends ExperimentAttributeConfig, TagsInput.ReactTagsInputProps {
    label?: string | null;
}

/**
 * Field to allow use select tags in an input field. We use ${react-tagsinput} for our JSX content.
 * Reference: https://www.npmjs.com/package/react-tagsinput
 */
export abstract class TagField extends ExperimentAttribute {
    protected displayConfig!: TagFieldConfig;
    protected addItemFieldConfig!: InputFieldConfig;

    parseValueFromEvent = (event: any): string[] => event;

    getTags = (): string[] => this.displayConfig.value;
    
    setValue = async(newValue?: string[]) => {
        const newDisplayValue = newValue ? newValue.join(', ') : '';
        const { isValid, errorText } = this.validate(newValue, this.validationRules);

        this.displayConfig = {
            ...this.displayConfig,
            touched: true,
            errorText,
            value: newValue ? newValue : []
        };
        
        await new Promise((resolve) => this.setState({ displayValue: newDisplayValue, validity: isValid }, () => resolve(newValue)));
    }

    onAddItemChangeEvent = (event: CustomEvent<Input.ChangeDetail>) => this.setState({ secondaryValue: event.detail.value });

    addItemToBackend = async(_newValue: string): Promise<boolean> => { return true; }

    removeItemFromBackend = async(_value: string): Promise<boolean> => { return true; }

    onAddToken = async(event: CustomEvent<Input.KeyDetail>) => {
        const keyPressed = event.detail.keyCode;
        if (keyPressed === 13 || keyPressed === 9) {
            const newValue = this.state.secondaryValue;
            
            if (newValue?.trim()) {
                this.addItemFieldConfig.value = '';

                this.setState({ editInProgress: true });
                await this.addItemToBackend(newValue).then(() => { 
                    this.setState({ 
                        editInProgress: false,
                        secondaryValue: '',
                        displayTokens: [
                            ...this.state.displayTokens!,
                            {
                                label: newValue!,
                                dismissLabel: `Remove ${newValue}`
                            }
                        ]
                    });
                });
            }
        };
    }

    onRemoveToken = async(event: CustomEvent<TokenGroup.DismissDetail>) => {
        const itemIndex = event.detail.itemIndex;
        const item = this.state.displayTokens![itemIndex].label;

        this.setState({ editInProgress: true });
        await this.removeItemFromBackend(item).then(() => { 
            this.setState({ 
                editInProgress: false,
                displayTokens: [
                    ...this.state.displayTokens!.slice(0, itemIndex),
                    ...this.state.displayTokens!.slice(itemIndex + 1),
                ]
            });
        });
    }

    renderViewMode = (): JSX.Element => (
        <div data-testid={'display-wrapper'} style={{ display: 'table-cell' }}>
            <div className='awsui-util-label'><strong><u>{this.displayConfig.label}</u></strong></div>
            <div style={{ display: 'inline ' }}>
                { !this.state.editInProgress ? <Input value={this.state.secondaryValue!} {...this.addItemFieldConfig}/> : <Spinner size='big'/>}
            </div> 
            {this.state.displayTokens && this.state.displayTokens.length > 0 ? 
                <div style={{ display: 'inline ' }}>
                    <TokenGroup items={this.state.displayTokens} onDismiss={this.onRemoveToken}/>
                </div> : null}
        </div>
    );

    getPolarisElement = () => <TagsInput {...this.displayConfig}/>
}