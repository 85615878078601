import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Cards } from '@amzn/awsui-components-react/polaris';
import { QuickLinkSectionProps } from '../../interfaces/IProps';

export interface QuickLinkCardItem {
    name: string;
    link: string;
}

const quickLinkCardsPerRow = [
    {
        'cards': 1
    },
    {
        'minWidth': 500,
        'cards': 2
    }
];

const useStyles = makeStyles({
    cardItem: {
        textAlign: 'center',
        padding: '50px',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

interface QuickLinkCardProps {
    item: QuickLinkCardItem;
}

const QuickLinkCardContent: FunctionComponent<QuickLinkCardProps> = ({ item }) => {
    const classes = useStyles();
    return (
        <Link to={item.link}>
            <div className={classes.cardItem}>
                <h2 className={classes.cardItem}>{item.name}</h2>
            </div>
        </Link>
    );
};

const quickLinkCardsDefinitions = {
    header: null,
    sections: [
        {
            id: 'content',
            header: null,
            content: (item: QuickLinkCardItem) => <QuickLinkCardContent item={item} />
        },
    ]
};

export const QuickLinksSection: FunctionComponent<QuickLinkSectionProps> = ({ testId , cardItems }) => (
    <div data-testid={`${testId}-quicklink`} className="custom-home-main-content-area">
        <Cards cardDefinition={quickLinkCardsDefinitions} items={cardItems} cardsPerRow={quickLinkCardsPerRow} />
    </div>
);




