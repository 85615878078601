export enum Realm {
    NA = 'na',
    EU = 'eu'
}

export enum Portal {
    ADMIN = 'admin',
    BASIC = 'basic'
}

export enum Stage {
    BETA = 'beta',
    GAMMA = 'gamma',
    PROD = 'prod',
    DEV = 'dev'
}

export enum PreSignedUrlType {
    UPLOAD = 'upload',
    DOWNLOAD = 'download'
}

export enum HTTPMethod {
    POST = 'POST',
    PUT = 'PUT',
    GET = 'GET'
}

export interface EndpointMap {
    [name: string]: string;
}

export interface StepDetailsQueryParams {
    stepId: number;
    experimentId: string;
    experimentIntegerId: number;
    date: string;
}