import { URL } from 'url';
import { Stage, StepDetailsQueryParams } from '../api/api-constants';
import { PROD_LIMESTONE_PORTAL_URL } from '../api/experiment-service/api-endpoints';

export const parseStageFromUrl = (url: URL): Stage => {
    const hostname = url.hostname;

    if (hostname === PROD_LIMESTONE_PORTAL_URL) {
        return Stage.PROD;
    }

    const stage = hostname?.split('.')[0];
    switch (stage) {
        case Stage.GAMMA: return Stage.GAMMA;
        case Stage.BETA: return Stage.BETA;
        default: return Stage.DEV;
    }
};

export const parseStepDetailsFromUrl = (url: URL): StepDetailsQueryParams => {
    const urlSearchParams = url.searchParams;

    return {
        stepId: Number(urlSearchParams.get('stepId')),
        experimentId: urlSearchParams.get('experimentId')!,
        experimentIntegerId: Number(urlSearchParams.get('experimentIntegerId')),
        date: urlSearchParams.get('date')!
    };
};