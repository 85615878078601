export const AttributeLabels = {
    MARKETPLACE: 'Marketplace',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    PRODUCT_FAMILY: 'Product Family',
    PRIMARY_BUSINESS_GROUP: 'Primary Business Group',
    SECONDARY_BUSINESS_GROUPS: 'Secondary Business Groups',
    PRIMARY_OWNER: 'Primary Owner',
    SECONDARY_OWNERS: 'Secondary Owners',
    OBSERVERS: 'Observers',
    TAGS: 'Tags',
    RABL_REGION_ID: 'RABL Region ID',
    DISCRIMINATOR: 'Discriminator',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    OFFERS_FILE: 'Product Selection File',
    TREATMENT_BOUNDARIES: 'Treatment Boundaries',
    REGION_CREATION_STATUS: 'RABL Region Status',
    CURRENT_STATUS: 'Current Status',
    LAST_REFRESHED: 'Last Refreshed',
    BUSINESS_APPROVER: 'L8 Contact',
    REJECTION_REASON: 'Rejection Reason'
};

export const TableHeaders = {
    MY_EXPERIMENTS: 'My Experiments',
    APPROVERS_QUEUE: 'Approvers Queue',
    ALL_EXPERIMENTS: 'All Running & Upcoming Experiments',
    BOUNDARY_OPTIONS: 'Boundary Set Options'
};

export const DataTableNames = {
    DOWNSTREAM_METRICS: 'Downstream Metrics'
};

export const UserAttributeLabels = {
    GIVEN_NAME: 'given_name',
    LAST_NAME: 'family_name',
    USER_ROLES: 'custom:LDAP_GROUP',
    ADMIN_ROLE: 'admin',
    CREATOR_ROLE: 'creator',
    DEV_ROLE: 'dev'
};