import React, { FunctionComponent } from 'react';
import { ColumnLayout } from '@amzn/awsui-components-react/polaris';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { PageSection } from '../../layout/PageSection';
import { IProps } from '../../interfaces/IProps';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import InfoIcon from '@material-ui/icons/Info';
import UnsubscribeIcon from '@material-ui/icons/Unsubscribe';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { ExternalLinks } from '../../constants/display/external-links';

const useStyles = makeStyles((theme) => ({
    header: {
        textAlign: 'center',
        padding: '20x',
        margin: '15px 5px 0 0'
    },
    container: {
        marginBottom: '30px',
        height: '140px'
    },
    box: {
        alignContent: 'center',
        textAlign: 'center',
        height: '50px',
        padding: '40x'
    },
    button: {
        marginTop: '10px'
    },
    pageSection: {
        height: '140px'
    }
}));

export const QuickStartSection: FunctionComponent<IProps> = ({ testId }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <PageSection testId={`${testId}-quickstart`} className={classes.pageSection}>
                <ColumnLayout columns={4} variant='text-grid'>
                    <div data-awsui-column-layout-root="true">
                        <div className={classes.box}>
                            <Typography variant='h3'><div>Learn more about ReSES</div></Typography>
                            <a href={ExternalLinks.WIKI.href}>
                                <Button
                                    variant='contained'
                                    size='medium'
                                    color='primary'
                                    className={classes.button}
                                    endIcon={<InfoIcon />}>
                                    <Typography variant='h5'><div>Wiki</div></Typography>
                                </Button>
                            </a>
                        </div>
                        <div className={classes.box}>
                            <Typography variant='h3'><div>Get permissions to create an experiment</div></Typography>
                            <a href={ExternalLinks.EXPERIMENT_INTAKE.href}>
                                <Button
                                    variant='contained'
                                    size='medium'
                                    color='primary'
                                    className={classes.button}
                                    endIcon={<VerifiedUserIcon />}>
                                    <Typography variant='h5'><div>SIM</div></Typography>
                                </Button>
                            </a> 
                        </div>
                        <div className={classes.box}>
                            <Typography variant='h3'><div>Submit a feature request</div></Typography>
                            <a href={ExternalLinks.FEEDBACK_INTAKE.href}>
                                <Button
                                    variant='contained'
                                    size='medium'
                                    color='primary'
                                    className={classes.button}
                                    endIcon={<FeedbackIcon />}>
                                    <Typography variant='h5'><div>Feedback</div></Typography>
                                </Button>
                            </a> 
                        </div>
                        <div className={classes.box}>
                            <Typography variant='h3'><div>Subscribe to our mailing list!</div></Typography>
                            <a href={ExternalLinks.MAILING_LIST.href}>
                                <Button
                                    variant='contained'
                                    size='medium'
                                    color='primary'
                                    className={classes.button}
                                    endIcon={<UnsubscribeIcon />}>
                                    <Typography variant='h5'><div>Subscribe</div></Typography>
                                </Button>
                            </a> 
                        </div>
                    </div>
                </ColumnLayout>
            </PageSection>
        </div>
    );
};