import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { MetadataAttribute } from '../../enums/CommonTypes';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { AttributeLabels } from '../../constants/display/string-constants';
import { DateField, DateFieldConfig } from '../fields/DateField';

export class EndDate extends DateField {
    protected displayConfig: DateFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true };
        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.validationRules = { required: true };

        this.displayConfig = {
            label: AttributeLabels.END_DATE,
            editable: false,
            touched: false,
            isDateEnabled: (date: Date) => this.isDateEnabled(date),
            hintText: 'Required *',
            errorText,
            onChange: (event) => this.onChangeEvent(event, MetadataAttribute.END_DATE),
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    isDateEnabled(date: Date): boolean {
        if (date.getDay() === 6 || date.getDay() === 0) {
            return false;
        }

        const lowerBound = this.props.startDate ? new Date(this.props.startDate) : new Date();
        if (date < lowerBound) {
            return false;
        }

        return true;
    }
}