import { QuickLinkCardItem } from '../../pages/Home/QuickLinksSection';

/**
 * Exported for usage in home page test
 */
export const adminQuickLinkCardItems: QuickLinkCardItem[] = [
    {
        'name': 'All Experiments',
        'link': '/all-experiments'
    },
    {
        'name': 'Manage Static Configuration Data',
        'link': '/static-config-data'
    }
];

export const basicQuickLinkCardItems: QuickLinkCardItem[] = [
    {
        'name': 'Create Experiment',
        'link': '/create-experiment'
    },
    {
        'name': 'My Experiments',
        'link': '/my-experiments'
    },
    {
        'name': 'Approve Experiments',
        'link': '/approvers-queue'
    },
    {
        'name': 'Experiments Currently Running',
        'link': '/search-experiments'
    }
];