import { InputField, InputFieldConfig } from '../fields/InputField';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { MetadataAttribute } from '../../enums/CommonTypes';
import { Realm } from '../../api/api-constants';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { AttributeLabels } from '../../constants/display/string-constants';

export class Title extends InputField {
    protected displayConfig: InputFieldConfig;
    private experimentServiceAPI: LemsApiHandler;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;
        const realm = props.realm ? props.realm : Realm.NA;
        this.experimentServiceAPI = new ApiHandler(realm);

        this.validationRules = { required: true, maxLength: 128 };
        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.TITLE,
            editable: true,
            type: 'text',
            placeholder: '128 char limit',
            touched: false,
            hintText: 'Required *',
            errorText,
            onChange: (event) => this.onChangeEvent(event, MetadataAttribute.TITLE),
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    editSubmitButtonClicked = async(): Promise<boolean> => {
        this.setState({ editInProgress: true });
        const response = await this.experimentServiceAPI.updateExperimentTitle(this.props.experimentId!, this.getPayloadValue())
            .finally(() => { 
                this.setState({ editInProgress: false, displayMode: DisplayMode.VIEW }); 
            });
        
        return response;
    }
}