import React, { FunctionComponent } from 'react';
import {
    Table,
    TableContentSelector,
    TableFiltering,
    TablePageSizeSelector,
    TablePagination,
    TablePreferences
} from '@amzn/awsui-components-react/polaris';
import { ErrorBoundary } from './ErrorBoundary';
import TableSelection from '@amzn/awsui-components-react/polaris/table-selection';

export interface DisplayTableProps {
    title?: JSX.Element;
    items: any[];
    tableLoading: boolean;
    columnDefinitions: Array<Table.ColumnDefinition<Table.Item>>;
    columnOptions: Array<TableContentSelector.ContentDescriptionGroup>;
    pageSizeOptions: Array<TablePageSizeSelector.Option>;
    selectedItem?: Table.Item;
    paginationEnabled?: boolean;
    filteringEnabled?: boolean;
    preferencesEnabled?: boolean;
    tableSelection?: (event: CustomEvent<TableSelection.SelectionChangeDetail<any>>) => void;
}

export const DisplayTable: FunctionComponent<DisplayTableProps> = (props: DisplayTableProps) => {
    const empty = <div className="awsui-util-t-c">
        <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No resources</b>
        </div>
        <p className="awsui-util-mb-s">No resources to display.</p>
    </div>;

    const content = 
        (
            <Table
                columnDefinitions={props.columnDefinitions}
                items={props.items}
                header={props.title}
                resizableColumns={true}
                empty={empty}
                wrapLines={false}
                loading={props.tableLoading}
                loadingText={'Loading resources'}>
                {props.filteringEnabled && (<TableFiltering filteringPlaceholder="Search"/>)}
                {props.paginationEnabled && (<TablePagination pageSize={15} openEnd={true}/>)}
                {props.tableSelection && (<TableSelection onSelectionChange={props.tableSelection} selectionType='single'/>)}
                {props.preferencesEnabled &&
                    (<TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel">
                        <TableContentSelector title="Select visible columns" options={props.columnOptions} />
                        <TablePageSizeSelector title="Page size" options={props.pageSizeOptions} />
                    </TablePreferences>)
                }
            </Table>
        );

    return (
        <ErrorBoundary>
            {content}
        </ErrorBoundary>
    );
};
