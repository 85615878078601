import { OfferValidationStatusType } from '../../enums/OfferStatusTypes';
import { LimestoneExperiment } from '../../interfaces/LimestoneExperiment';

export interface OwnerDto {
    alias: string;
    primary: boolean;
}

export interface BusinessGroupDto {
    businessGroup: string;
    primary: boolean;
}

export interface ObserverDto {
    alias: string;
}

export interface TagDto {
    tag: string;
}

export interface MetadataPayload {
    marketplaceId: string,
    title: string,
    description: string,
    productFamily: string,
    businessGroupsDto: BusinessGroupDto[],
    ownersDto: OwnerDto[],
    observersDto?: ObserverDto[],
    tagsDto?: TagDto[],
    regionId: string;
    listerDiscriminator: string;
    experimentStartDate: string;
    experimentEndDate: string;
    businessApprover: string;
}

export interface ExperimentOfferDto {
    asin: string;
    merchantId: string;
    sku: string;
    marketplaceId?: string;
    offerValidationStatus?: string;
    offerRegionalizationStatus?: string;
    offerValidationFailedReasons?: string[];
}

export interface ExperimentBoundaryDto {
    boundaryId: string;
    boundaryDescription?: string;
    experimentRegionType: string;
}

export interface ExperimentApproverDto {
    alias: string;
    type: string;
    status: string;
    rejectReason: string;
}

export interface ExperimentOutput extends MetadataPayload {
    experimentId: string;
    experimentIntegerId: string;
    currentStatus?: string;
    experimentType?: string;
    program?: string;
    creationDate?: string;
    lastUpdatedDate?: string;
    regionCreationStatus: string;
}

export interface CreateExperimentPayload {
    experimentMetadata: MetadataPayload
}

export enum ArtifactType {
    PRODUCT_SELECTION = 0,
    REGION_SELECTION = 1
}


export interface CreateExperimentResponse {
    success: boolean;
    experimentId: string;
    experimentIntegerId: number;
}

export interface ExecuteExperimentResponse {
  success: boolean
}

export interface StartExperimentResponse {
    success: boolean
}

export interface EndExperimentResponse {
    success: boolean
}

export interface ReadExperimentResponse {
    experiment: LimestoneExperiment;
}

export interface UpdateExperimentBoundariesPayload {
    experimentId: string;
    boundaries: ExperimentBoundaryDto[];
}

export interface UpdateExperimentBoundariesResponse {
    success: boolean;
}

export interface GetExperimentsByOwnerResponse {
    experiments: LimestoneExperiment[];
}

export interface GetExperimentBoundariesResponse {
    boundaries: ExperimentBoundaryDto[];
}

export interface UpdateExperimentTitlePayload {
    experimentId: string;
    title: string;
}

export interface UpdateExperimentTitleResponse {
    success: boolean;
}

export interface UpdateExperimentDescriptionPayload {
    experimentId: string;
    description: string;
}

export interface UpdateExperimentDescriptionResponse {
    success: boolean;
}

export interface AddSecondaryOwnerPayload {
    experimentId: string;
    owner: OwnerDto;
}

export interface AddSecondaryOwnerResponse {
    success: boolean;
}

export interface RemoveSecondaryOwnerPayload {
    experimentId: string;
    owner: OwnerDto;
}

export interface RemoveSecondaryOwnerResponse {
    success: boolean;
}

export interface AddObserverPayload {
    experimentId: string;
    observer: ObserverDto;
}

export interface AddObserverResponse {
    success: boolean;
}

export interface RemoveObserverPayload {
    experimentId: string;
    observer: ObserverDto;
}

export interface RemoveObserverResponse {
    success: boolean;
}

export interface AddTagPayload {
    experimentId: string;
    tag: TagDto;
}

export interface AddTagResponse {
    success: boolean;
}

export interface RemoveTagPayload {
    experimentId: string;
    tag: TagDto;
}

export interface RemoveTagResponse {
    success: boolean;
}

export interface GetAllOffersInExperimentResponse {
    experimentOffers: ExperimentOfferDto[];
}

export interface SubmitExperimentBoundariesPayload {
    experimentId: string;
    boundaries: ExperimentBoundaryDto[];
}

export interface SubmitExperimentBoundariesResponse {
    success: boolean;
}

export interface GetExperimentsByApproverResponse {
    experiments: ExperimentOutput[];
}

export interface SubmitApprovalDecisionPayload {
    approverAlias: string;
    approverRole: string;
    updatedStatus: string;
    rejectionReason?: string;
}

export interface SubmitApprovalDecisionResponse {
    success: boolean;
}

export interface GetAllExperimentApproversResponse {
    approvers: ExperimentApproverDto[];
}

export interface ExperimentUserRequestOutput {
    requestId: string;
    requestType: string;
    validationStatus: OfferValidationStatusType;
    validationFailureReasons: string;
    requestStatus: string;
}

export interface BoundarySetDto {
    boundaries: Object;
    attributes: Object;
}

export interface FinalizeExperimentBoundariesResponse {
    success: true;
}

export interface GetExperimentBoundaryOptionsResponse {
    boundarySets: BoundarySetDto[];
}
