import React, { FunctionComponent } from 'react';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';

export interface ProgressBarProps extends LinearProgressProps {
    label: string;
    current: number;
    total: number;
    refreshHandler: () => Promise<void>;
}

export const ProgressBar: FunctionComponent<ProgressBarProps> = (props: ProgressBarProps) => {
    const percentageValue = Math.min(Math.round(props.current/props.total * 100), 100);

    return (
        <Box display="flex" alignItems="center" padding="10px">
            <Box width="20%" mr={1}>
                <Typography variant="body2">{props.label}</Typography>
            </Box>
            <Box width="60%" mr={1}>
                <LinearProgress variant="determinate" value={percentageValue}/>
            </Box>
            <Box width="15%" mr={1}>
                <Typography variant="body2" color="textSecondary">{`${props.current}/${props.total}`}</Typography>
            </Box>
            <Box width="5%" mr={1}>
                <IconButton size="medium" onClick={props.refreshHandler}> <RefreshIcon/> </IconButton>
            </Box>
        </Box>
    );
};
