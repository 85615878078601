export const constructDate = (yyMMdd: string): Date => {
    const dateParts: number[] = yyMMdd.split('-').map((part: string) => Number.parseInt(part));
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
};

export const constructDateString = (date: Date): string => {
    const dateString = new Date(date);
    let month = '' + (dateString.getMonth() + 1);
    let day = '' + dateString.getDate();
    const year = dateString.getFullYear();

    if (month.length < 2) 
    {month = '0' + month;}
    if (day.length < 2) 
    {day = '0' + day;}

    return [year, month, day].join('-');
};