import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Wizard, FormSection, ColumnLayout } from '@amzn/awsui-components-react/polaris';
import * as NOTIFICATION_MESSAGES from '../../constants/display/flashbar-messages';
import { PageProps } from '../../interfaces/IProps';
import { SummarySection } from '../../common/SummarySection';
import { FormFactory } from '../../factories/FormFactory';
import { StepId, wizardI18N, INotifications } from '../CreateExperiment/CreateWizardTypes';
import { IButtonHandler } from '../../interfaces/IButtonHandler';
import { ExperimentStep } from '../../enums/CommonTypes';
import { LimestoneExperiment } from '../../interfaces/LimestoneExperiment';
import * as FormUtils from '../../utils/form-utils';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { experimentDetailPage } from '..';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { UserInputModal } from '../../common/UserInputModal';
import { WizardCancelModalAttributes, WizardSubmitModalAttributes } from '../../constants/display/modal-constants';
import { handleErrorResponse } from '../../utils/error-handler-utils';

/** Props for the Create Experiment Wizard. */
export interface CreateWizardProps extends RouteComponentProps, PageProps {}

/**
 * State of the Create Experiment Wizard.
 * @param activeStepIndex current step which is displayed on the wizard
 * @param experiment the limestone experiment entity which is created by filling the forms
 * @param pristineState pristine (untouched) state of the current step of the wizard
 * @param buttonLoadingState the loading state of the primary button of current step of the wizard
 * @param showCancelModal flag to represent whether cancel modal should be shown
 * @param showSubmitModal flag to represent whether submit modal should be shown
 * @param notifications the state of notifications to be displayed
 */
export interface CreateWizardState {
    activeStepIndex: number,
    experiment: LimestoneExperiment,
    pristineState: boolean,
    componentLoadingState: boolean,
    buttonLoadingState: boolean,
    showCancelModal: boolean,
    showSubmitModal: boolean,
    notifications: INotifications
}

export class CreateExperimentWizard extends Component<CreateWizardProps, CreateWizardState> {
    private readonly wizardButtonHandlers: any;
    private readonly cancelModalHandlers: IButtonHandler;
    private readonly submitModalHandlers: IButtonHandler;

    /** Experiment Service handler instance which provides api to get the experiment data from the backend */
    private experimentServiceAPI: LemsApiHandler;

    /** Factory class which returns the appropriate form. */
    private formFactory: FormFactory;

    public constructor(props: CreateWizardProps) {
        super(props);

        this.formFactory = new FormFactory();

        this.experimentServiceAPI = new ApiHandler(props.realm);

        this.state = {
            activeStepIndex: 0,
            pristineState: true,
            componentLoadingState: false,
            buttonLoadingState: false,
            showCancelModal: false,
            showSubmitModal: false,
            notifications: {},
            experiment: FormUtils.createEmptyLimestoneExperiment(),
        };

        this.experimentServiceAPI = new ApiHandler(props.realm);

        this.wizardButtonHandlers = {
            cancel: () => this.setState({ showCancelModal: true }),
            previous: (event: CustomEvent) => this.onWizardPreviousClick(event),
            next: (event: CustomEvent) => this.onWizardNextClick(event),
            submit: () => this.setState({ showSubmitModal: true })
        };
    
        this.cancelModalHandlers = {
            dismiss: () => this.setState({ showCancelModal: false }),
            submit: () => props.history.push('/')
        };

        this.submitModalHandlers = {
            dismiss: () => this.setState({ showSubmitModal: false }),
            submit: () => this.submitResponse()
        };
    }

    updateFormState = (fieldId: string, payloadValue: any, displayValue: string, isValid: boolean): void => {
        if (this.state.activeStepIndex === StepId.METADATA) {
            const updatedMetadata = this.state.experiment.metadata;
            updatedMetadata[fieldId].updateAttributeDetails(isValid, payloadValue, displayValue);
            this.setState({ experiment: { ...this.state.experiment, metadata: updatedMetadata } });
        } else if (this.state.activeStepIndex === StepId.PRODUCT_SELECTION) {
            const updatedProductSelection = this.state.experiment.productSelection;
            updatedProductSelection[fieldId].updateAttributeDetails(isValid, payloadValue, displayValue);
            this.setState({ experiment: { ...this.state.experiment, productSelection: updatedProductSelection } });
        }
    }

    validateStep = (): boolean => {
        let currentStepData: ExperimentStep;

        if (this.state.activeStepIndex === StepId.METADATA) {
            currentStepData = this.state.experiment.metadata;
        } else {
            currentStepData = this.state.experiment.productSelection;
        }

        let formIsValid = true;
        Object.keys(currentStepData).forEach((key) => {
            formIsValid = formIsValid && currentStepData[key].isValid;
        });

        return formIsValid;
    }

    /* istanbul ignore next */
    submitResponse = async() => {
        this.setState({ buttonLoadingState: true, showSubmitModal: false });

        const createExperimentResponse = await this.experimentServiceAPI.uploadMetadata(this.state.experiment.metadata)
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.uploadMetadata.FAIL!))
            .finally(() => this.setState({ buttonLoadingState: false }));

        if (createExperimentResponse) {
                this.props.setNotification!(NOTIFICATION_MESSAGES.uploadMetadata.SUCCESS);
                
                this.setState({ buttonLoadingState: true });
                const { experimentId, experimentIntegerId } = createExperimentResponse;
                const redirectUri = `${experimentDetailPage.path}?experimentId=${experimentId}&experimentIntegerId=${experimentIntegerId}`;
    
                await this.experimentServiceAPI.uploadProductSelection(this.state.experiment.productSelection, experimentId, this.state.experiment.metadata.marketplace.payloadValue)
                    .then(() => {
                        this.props.setNotification!(NOTIFICATION_MESSAGES.uploadProductSelection.SUCCESS);
                        setTimeout(() => this.props.history.push(redirectUri), 3000);
                    })
                    .catch((error) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.uploadProductSelection.FAIL!))
                    .finally(() => this.setState({ buttonLoadingState: false }));
        }
    };

    onWizardPreviousClick = (event: CustomEvent) => {
        event.preventDefault();
        this.setState({ activeStepIndex: this.state.activeStepIndex - 1 });
    }

    onWizardNextClick = (event: CustomEvent) => {
        event.preventDefault();
        this.setState({ pristineState: false });

        if (this.validateStep()) {
            this.setState({ activeStepIndex: this.state.activeStepIndex + 1, pristineState: true });
        }
    };
    
    /* istanbul ignore next */
    renderSummary = () => {
        return (
            <FormSection data-testid={`create-section-${this.state.activeStepIndex}`}>
                <SummarySection header={'Experiment Definition'} columns={3} items={this.state.experiment.metadata} />
                <SummarySection header={'Experiment Selection'} columns={1} items={this.state.experiment.productSelection} handler={(_) => this.setState({ activeStepIndex: StepId.PRODUCT_SELECTION })}/>
            </FormSection>
        );
    };

    getSteps = (): Wizard.Step[]  => {
        return [
            {
                title: 'Experiment Definition',
                description: 'The information entered below will be reviewed by the ReSES team, L8 Business and Finance Approvers. Please enter the RABL Region ID and Discriminator provided to you during onboarding.',
                content: (
                    <FormSection data-testid={'metadata-section'}>
                        <ColumnLayout columns={2}>
                            {this.formFactory.getMetadataForm(this.updateFormState, DisplayMode.CREATE, false, this.state.experiment.metadata, undefined, this.props.realm)}
                        </ColumnLayout>
                    </FormSection>
                ),
                errorText: (this.validateStep() || this.state.pristineState) ? null : 'Complete All Required Fields'
            },
            {
                title: 'Upload ASIN Selection',
                description: 'We are currently supporting a csv file for the product selection (You may download an example file to using the button below to view the format). We currently only support retail offers for experiment selection.',
                content: (
                    <FormSection data-testid={'product-selection-section'}>
                        <ColumnLayout columns={1}>
                            {this.formFactory.getProductSelectionForm(this.updateFormState, DisplayMode.CREATE, false, this.state.experiment.productSelection)}
                        </ColumnLayout>
                    </FormSection>
                ),
                errorText: (this.validateStep()|| this.state.pristineState) ? null : 'Upload selection file'
            },
            {
                title: 'Confirm and Submit',
                description: 'This is the summary of your experiment.',
                content: this.renderSummary()
            }
        ];
    };
    
    render() {
        return (
            <div className='awsui-util-container' style={{ padding: '20px' }}>
                <UserInputModal 
                    visible={this.state.showCancelModal}
                    buttonHandlers={this.cancelModalHandlers}
                    {...WizardCancelModalAttributes}
                />

                <UserInputModal 
                    visible={this.state.showSubmitModal}
                    buttonHandlers={this.submitModalHandlers}
                    {...WizardSubmitModalAttributes}
                />

                <Wizard
                    data-testid={'create-wizard'}
                    steps={this.getSteps()}
                    activeStepIndex={this.state.activeStepIndex}
                    i18nStrings={wizardI18N}
                    isLoadingNextStep={this.state.buttonLoadingState}
                    onCancelButtonClick={this.wizardButtonHandlers.cancel}
                    onNextButtonClick={this.wizardButtonHandlers.next}
                    onPreviousButtonClick={this.wizardButtonHandlers.previous}
                    onSubmitButtonClick={this.wizardButtonHandlers.submit}
                />
            </div>
        );
    }
}

export default withRouter(CreateExperimentWizard);