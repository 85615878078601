import { DisplayAttribute } from '../../../interfaces/DisplayAttribute';
import { LimestoneExperimentBoundaries } from '../../../interfaces/LimestoneExperiment';
import * as LambdaModel from '../lambda-model-types';
import { BoundarySet } from '../../../interfaces/BoundarySet';

export const convertBoundarySetsToBoundaryDtos = (treatmentBoundaries: string[]): LambdaModel.ExperimentBoundaryDto[] => {
    return treatmentBoundaries.map((treatmentBoundary: string) => {
        return {
            boundaryId: treatmentBoundary.split('::')[0],
            boundaryDescription: treatmentBoundary.split('::')[1],
            experimentRegionType: 'TREATMENT'
        };
    });
};

export const convertGetBoundariesResponseToDisplay = (response: LambdaModel.GetExperimentBoundariesResponse): LimestoneExperimentBoundaries => {
    const boundaries = response.boundaries.map((boundary) => {
        if (boundary.boundaryDescription) {
            return `${boundary.boundaryId}: ${boundary.boundaryDescription}`;
        }

        return boundary.boundaryId;
    });

    return {
        treatmentBoundaries: new DisplayAttribute('Treatment Boundaries', true, boundaries, boundaries.join(', '))
    };
};

export const convertBoundarySetDtosToBoundarySet = (boundarySetDtos: LambdaModel.BoundarySetDto[]): BoundarySet[] => {
    return boundarySetDtos.map((boundarySetDto) => {
        return {
            boundaries: new Map(Object.entries(boundarySetDto.boundaries)),
            attributes: new Map(Object.entries(boundarySetDto.attributes))
        };
    });
};