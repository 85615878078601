import { DisplayAttribute } from '../interfaces/DisplayAttribute';
import { LimestoneExperiment } from '../interfaces/LimestoneExperiment';
import { AttributeLabels } from '../constants/display/string-constants';

export const createEmptyLimestoneExperiment = (): LimestoneExperiment => {
    return {
        metadata: {
            marketplace: new DisplayAttribute(AttributeLabels.MARKETPLACE),
            title: new DisplayAttribute(AttributeLabels.TITLE),
            description: new DisplayAttribute(AttributeLabels.DESCRIPTION),
            productFamily: new DisplayAttribute(AttributeLabels.PRODUCT_FAMILY),
            primaryBusinessGroup: new DisplayAttribute(AttributeLabels.PRIMARY_BUSINESS_GROUP),
            secondaryBusinessGroups: new DisplayAttribute(AttributeLabels.SECONDARY_BUSINESS_GROUPS, true, []),
            primaryOwner: new DisplayAttribute(AttributeLabels.PRIMARY_OWNER),
            secondaryOwners: new DisplayAttribute(AttributeLabels.SECONDARY_OWNERS, true, []),
            observers: new DisplayAttribute(AttributeLabels.OBSERVERS, true, []),
            tags: new DisplayAttribute(AttributeLabels.TAGS, true, []),
            rablRegionId: new DisplayAttribute(AttributeLabels.RABL_REGION_ID, true),
            discriminator: new DisplayAttribute(AttributeLabels.DISCRIMINATOR, true),
            startDate: new DisplayAttribute(AttributeLabels.START_DATE, true),
            endDate: new DisplayAttribute(AttributeLabels.END_DATE, true),
            regionCreationStatus: new DisplayAttribute(AttributeLabels.REGION_CREATION_STATUS, true),
            businessApprover: new DisplayAttribute(AttributeLabels.BUSINESS_APPROVER, true)
        },
        productSelection: {
            offersFile: new DisplayAttribute(AttributeLabels.OFFERS_FILE)
        },
        regionSelection: {
            treatmentBoundaries: new DisplayAttribute(AttributeLabels.TREATMENT_BOUNDARIES)
        },
        currentStatus: {
            currentStatus: new DisplayAttribute(AttributeLabels.CURRENT_STATUS, true),
            lastRefreshed: new DisplayAttribute(AttributeLabels.LAST_REFRESHED, true)
        }
    };
};