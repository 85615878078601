/* istanbul ignore file */

import Papa from 'papaparse';

export const convertCSVToJSON = async(file: File): Promise<any> => {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            header: false,
            complete (results) {
                resolve(results.data);
            },
            error (error) {
                reject(error);
            }
        });
    });
};