import axios from 'axios';
import { LemsApiHandler } from './lems-api-handler';
import { LEMS_API_ENDPOINTS, LemsApiPaths } from '../api-endpoints';
import { PreSignedUrlType, Realm } from '../../api-constants';
import * as MetadataAdaptor from '../adaptors/metadata-adaptor';
import * as ReadExperimentAdaptor from '../adaptors/read-experiment-adaptor';
import * as ProductSelectionAdaptor from '../adaptors/product-selection-adaptor';
import * as RegionSelectionAdaptor from '../adaptors/region-selection-adaptor';
import * as ExperimentApproverAdaptor from '../adaptors/experiment-approver-adaptor';
import { OfferValidationStatusType } from '../../../enums/OfferStatusTypes';
import * as LambdaModelTypes from '../lambda-model-types';
import {
    LimestoneExperiment,
    LimestoneExperimentBoundaries,
    LimestoneExperimentMetadata,
    LimestoneExperimentSelection
} from '../../../interfaces/LimestoneExperiment';
import { ApprovalStatusType } from '../../../enums/ApprovalStatusType';
import { Approver } from '../../../interfaces/Approver';
import { ExperimentStatusType } from '../../../enums/ExperimentStatus';
import { ApiHandler } from '../../api-handler';
import { BoundarySet } from '../../../interfaces/BoundarySet';
import { ExperimentWorkflowType } from '../../../common/ExperimentWorkflowType';

export default class LemsApiHandlerImpl extends ApiHandler implements LemsApiHandler {
    public constructor(realm: Realm) {
        super(realm, LEMS_API_ENDPOINTS);
    }

    public uploadMetadata = async(metadata: LimestoneExperimentMetadata): Promise<LambdaModelTypes.CreateExperimentResponse> => {
        await this.authenticate();

        const payload = MetadataAdaptor.convertMetadataToPayload(metadata);
        const createExperimentResponse: LambdaModelTypes.CreateExperimentResponse = {
            experimentId: '',
            experimentIntegerId: -1,
            success: false
        };

        const response = await this.instance.post(LemsApiPaths.EXPERIMENT, payload, this.getConfig());

        if (!response) {
            console.error('No response from experiment service');
        }

        if (response.status === 200 && response.data.hasOwnProperty('experimentId')) {
            console.log(response);
            createExperimentResponse.success = true;
            createExperimentResponse.experimentId = response.data.experimentId;
            createExperimentResponse.experimentIntegerId = response.data.experimentIntegerId;
        } else {
            console.error('Invalid Response: ', response);
        }

        return createExperimentResponse;
    }

    createExperimentUserRequest = async(experimentId: string, requestType: string ): Promise<LambdaModelTypes.ExperimentUserRequestOutput> => {
        await this.authenticate();
        
        const url = `${LemsApiPaths.EXPERIMENT_USER_REQUEST}`;

        const payload = {
            experimentId: experimentId,
            requestType: requestType
        };

        const response = await this.instance.post(url, payload, this.getConfig());

        if (!response) {
            console.error('No response from experiment service');
        }

        const createExperimentUserRequestOutput: LambdaModelTypes.ExperimentUserRequestOutput = {
            requestId: '',
            requestType: '',
            validationStatus: OfferValidationStatusType.NOT_STARTED,
            validationFailureReasons: '',
            requestStatus: '',
        };

        if (response.status === 200 && response.data.hasOwnProperty('experimentUserRequestOutput')) {
            createExperimentUserRequestOutput.requestId = response.data.experimentUserRequestOutput.requestId;
            createExperimentUserRequestOutput.requestType = response.data.experimentUserRequestOutput.requestType;
            createExperimentUserRequestOutput.validationStatus = response.data.experimentUserRequestOutput.validationStatus;
            createExperimentUserRequestOutput.validationFailureReasons = response.data.experimentUserRequestOutput.validationFailureReasons;
            createExperimentUserRequestOutput.requestStatus = response.data.experimentUserRequestOutput.requestStatus;
        } else {
            console.log('Invalid Response: ', response);
        }

        return createExperimentUserRequestOutput;
    }

    public getPresignedS3Url = async(experimentId: string, artifactType: LambdaModelTypes.ArtifactType, marketplaceId: string, type: PreSignedUrlType): Promise<string> => {
        const url = type === PreSignedUrlType.UPLOAD ? LemsApiPaths.UPLOAD_ARTIFACT : LemsApiPaths.DOWNLOAD_ARTIFACT;
        const response = await this.sendHttpGetRequest(url, { params: { experimentId, artifactType, marketplaceId }, headers: this.config.headers });

        return response.presignedUrl;
    }

    public uploadProductSelection = async(productSelection: LimestoneExperimentSelection, experimentId: string, marketplaceId: string): Promise<boolean> => {
        const preSignedUrl = await this.getPresignedS3Url(experimentId, LambdaModelTypes.ArtifactType.PRODUCT_SELECTION, marketplaceId, PreSignedUrlType.UPLOAD);

        if (preSignedUrl === '') {
            console.error('Error in retrieving Pre Signed Url');
            return false;
        }
        console.log('PresignedUrl retreived: ' + preSignedUrl);

        const offers = ProductSelectionAdaptor.convertAsinsToExperimentOffers(productSelection.offersFile.payloadValue, this.stage, marketplaceId);
        const file = new File([JSON.stringify(offers, null, 2)], '1_product-selection.json', { type: 'application/json' });

        const response = await axios.put(preSignedUrl, file, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response) {
            console.error('Error in sending product selection file to S3');
            return false;
        }

        return true;
    }

    public updateExperimentBoundaries = async(experimentId: string, boundaries: string[]): Promise<LambdaModelTypes.UpdateExperimentBoundariesResponse> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/boundaries`;
        const boundaryDtos: LambdaModelTypes.ExperimentBoundaryDto[] = RegionSelectionAdaptor.convertBoundarySetsToBoundaryDtos(boundaries);

        const payload: LambdaModelTypes.UpdateExperimentBoundariesPayload = {
            experimentId,
            boundaries: boundaryDtos
        };
        
        return await this.sendHttpPutRequest(url, payload);
    }

    public executeExperiment = async(experimentId: string, workflowType: ExperimentWorkflowType): Promise<LambdaModelTypes.ExecuteExperimentResponse> => {
        await this.authenticate();
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/workflow-type/${workflowType}/execute`;

        return await this.sendHttpPostRequest(url, {});
    }

    public startExperiment = async(experimentId: string, experimentIntegerId: number): Promise<LambdaModelTypes.StartExperimentResponse> => {
        await this.authenticate();
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/id/${experimentIntegerId}/start`;

        return await this.sendHttpPutRequest(url, {});
    }

    public endExperiment = async (experimentId: string, experimentIntegerId: number): Promise<LambdaModelTypes.EndExperimentResponse> => {
        await this.authenticate();

        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/id/${experimentIntegerId}/end`;

        return await this.sendHttpPutRequest(url, {});
    }

    public readExperiment = async(experimentId: string, experimentIntegerId: number): Promise<LimestoneExperiment> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/id/${experimentIntegerId}`;
        const response = await this.sendHttpGetRequest(url, this.getConfig());

        return ReadExperimentAdaptor.convertReadResponseToExperiment(response.experiment);
    }

    public getExperimentsByOwner = async (owner: string): Promise<LimestoneExperiment[]> => {
        const url = LemsApiPaths.GET_EXPERIMENTS_BY_OWNER;
        const response = await this.sendHttpGetRequest(url, {
            headers: this.config.headers,
            params: { owner }
        });

        return response.experiments.map((experiment: LambdaModelTypes.ExperimentOutput) => {
            return ReadExperimentAdaptor.convertReadResponseToExperiment(experiment);
        });
    }

    public getExperimentsInGivenStatuses = async (statusList: ExperimentStatusType[]): Promise<LimestoneExperiment[]> => {
        const url = LemsApiPaths.GET_EXPERIMENTS_IN_GIVEN_STATUSES;
        const payload = {
            experimentStatuses: statusList
        };
        
        const response = await this.sendHttpPutRequest(url, payload);

        return response.experiments.map((experiment: LambdaModelTypes.ExperimentOutput) => {
            return ReadExperimentAdaptor.convertReadResponseToExperiment(experiment);
        });
    }

    public getExperimentsByStatus = async (status: string): Promise<LimestoneExperiment[]> => {
        const url = LemsApiPaths.GET_EXPERIMENTS_BY_STATUS + '/' + status;
        const response = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.experiments.map((experiment: LambdaModelTypes.ExperimentOutput) => {
            return ReadExperimentAdaptor.convertReadResponseToExperiment(experiment);
        });
    }

    public getExperimentById = async (experimentId: string): Promise<LimestoneExperiment> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/id`;
        const response = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return ReadExperimentAdaptor.convertReadResponseToExperiment(response.experiment);
    }

    public getAllExperimentBoundaries = async(experimentId: string): Promise<LimestoneExperimentBoundaries> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/boundaries`;
        const response = await this.sendHttpGetRequest(url, {
            headers: this.config.headers,
            params: { experimentId }
        });

        return RegionSelectionAdaptor.convertGetBoundariesResponseToDisplay(response);
    }

    public updateExperimentTitle = async(experimentId: string, updatedTitle: string): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/title`;
        const payload: LambdaModelTypes.UpdateExperimentTitlePayload = { experimentId, title: updatedTitle };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public updateExperimentDescription = async(experimentId: string, updatedDescription: string): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/description`;
        const payload: LambdaModelTypes.UpdateExperimentDescriptionPayload = { experimentId, description: updatedDescription };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public addSecondaryOwner = async(experimentId: string, newSecondaryOwner: LambdaModelTypes.OwnerDto): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/owners/secondary/add`;
        const payload: LambdaModelTypes.AddSecondaryOwnerPayload = { experimentId, owner: newSecondaryOwner };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public removeSecondaryOwner = async(experimentId: string, secondaryOwner: LambdaModelTypes.OwnerDto): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/owners/secondary/remove`;
        const payload: LambdaModelTypes.RemoveSecondaryOwnerPayload = { experimentId, owner: secondaryOwner };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public addObserver = async(experimentId: string, newObserver: LambdaModelTypes.ObserverDto): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/observers/add`;
        const payload: LambdaModelTypes.AddObserverPayload = { experimentId, observer: newObserver };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public removeObserver = async(experimentId: string, observer: LambdaModelTypes.ObserverDto): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/observers/remove`;
        const payload: LambdaModelTypes.RemoveObserverPayload = { experimentId, observer };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public addTag = async(experimentId: string, newTag: LambdaModelTypes.TagDto): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/tags/add`;
        const payload: LambdaModelTypes.AddTagPayload = { experimentId, tag: newTag };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public removeTag = async(experimentId: string, tag: LambdaModelTypes.TagDto): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/tags/remove`;
        const payload: LambdaModelTypes.RemoveTagPayload = { experimentId, tag };
        const response = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public getAllOffersInExperiment = async(experimentId: string, marketplaceId: string): Promise<LambdaModelTypes.ExperimentOfferDto[]> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/marketplace/${marketplaceId}/offers`;
        const response: LambdaModelTypes.GetAllOffersInExperimentResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers,
            params: { experimentId, marketplaceId }
        });

        return response.experimentOffers;
    }

    public getExperimentsByApprover = async(alias: string): Promise<LimestoneExperiment[]> => {
        const url = `${LemsApiPaths.EXPERIMENTS}/approver/${alias}`;
        const response: LambdaModelTypes.GetExperimentsByApproverResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.experiments.map((experiment: LambdaModelTypes.ExperimentOutput) => ReadExperimentAdaptor.convertReadResponseToExperiment(experiment));
    }

    public submitApprovalDecision = async(experimentId: string, role: string, alias: string, updatedStatus: ApprovalStatusType, rejectionReason?: string): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/approver/submit`;
        const payload: LambdaModelTypes.SubmitApprovalDecisionPayload = { approverAlias: alias, approverRole: role, updatedStatus, rejectionReason };
        const response: LambdaModelTypes.SubmitApprovalDecisionResponse = await this.sendHttpPutRequest(url, payload);

        return response.success;
    }

    public getAllExperimentApprovers = async(experimentId: string): Promise<Approver[]> => {
        const url =  `${LemsApiPaths.EXPERIMENT}/${experimentId}/approvers`;
        const response: LambdaModelTypes.GetAllExperimentApproversResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.approvers.map((approverDto: LambdaModelTypes.ExperimentApproverDto) => ExperimentApproverAdaptor.convertApproverDtoToApprover(approverDto));
    }

    public getStaticConfigurationData = async(realm: string): Promise<string> => {
        const url = `${LemsApiPaths.GET_STATIC_CONFIGURATION_DATA}`;
        const response = await this.sendHttpGetRequest(url, {
            headers: this.config.headers,
            params: { realm }
        });

        return response.configurations;

    }

    public updateStaticConfigurationData = async(newConfiguration: string, realm: string): Promise<boolean> => {
        const url = `${LemsApiPaths.UPDATE_STATIC_CONFIGURATION_DATA}`;
        const payload = {
            realm: realm,
            newConfiguration: newConfiguration,
        };
        const response = await this.sendHttpPutRequest(url, payload);
        return response.success;
    }

    getExperimentUserRequestsByRequestType = async (requestType: string): Promise<LambdaModelTypes.ExperimentUserRequestOutput[]> => {
        const url = `${LemsApiPaths.EXPERIMENT_USER_REQUEST}/request-type/${requestType}`;
        const response = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.experimentUserRequests;
    }

    getExperimentUserRequestOffers = async (requestId: string): Promise<LambdaModelTypes.ExperimentOfferDto[]> => {
        const url = `${LemsApiPaths.EXPERIMENT_USER_REQUEST}/requestId/${requestId}/offers`;
        const response = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return response.experimentOffers;
    }

    executeExperimentUserRequest = async(requestId: string): Promise<boolean> => {
        await this.authenticate();

        const url = `${LemsApiPaths.EXPERIMENT_USER_REQUEST}/${requestId}/execute`;
        const payload = {};

        const response = await this.instance.post(url, payload, this.getConfig());

        if (!response) {
            console.error('No response from experiment service');
            return false;
        }

        if (response.status === 200 && response.data.hasOwnProperty('success')) {
            return true;
        } else {
            return false;
        }
    }

    finalizeExperimentBoundaries = async(experimentId: string): Promise<boolean> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/boundaries/finalize`;
        const response: LambdaModelTypes.FinalizeExperimentBoundariesResponse = await this.sendHttpPutRequest(url, {});

        return response.success;
    }

    getExperimentBoundaryOptions = async(experimentId: string): Promise<BoundarySet[]> => {
        const url = `${LemsApiPaths.EXPERIMENT}/${experimentId}/boundary-sets`;
        const response: LambdaModelTypes.GetExperimentBoundaryOptionsResponse = await this.sendHttpGetRequest(url, {
            headers: this.config.headers
        });

        return RegionSelectionAdaptor.convertBoundarySetDtosToBoundarySet(response.boundarySets);
    }
}
