import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

export enum RablRegionCreationStatusType {
    SETUP_IN_PROGRESS = 'SETUP_IN_PROGRESS',
    AWAITING_USER_RESPONSE = 'AWAITING_USER_RESPONSE',
    BOUNDARIES_UPDATE_COMPLETE = 'BOUNDARIES_UPDATE_COMPLETE',
    UPDATING_TO_LIVE = 'UPDATING_TO_LIVE',
    LIVE = 'LIVE',
    MARKED_FOR_DEPRECATION = 'MARKED_FOR_DEPRECATION',
    DEPRECATED = 'DEPRECATED'
}

type StatusTypeMap = {
    [name in RablRegionCreationStatusType]: JSX.Element;
};

export const RegionCreationStatus: StatusTypeMap = {
    SETUP_IN_PROGRESS: <span className="awsui-util-status-info"><HourglassEmptyIcon fontSize='large'/> Setup In Progress</span>,
    AWAITING_USER_RESPONSE: <span className="awsui-util-status-info"><HourglassEmptyIcon fontSize='large'/> Awaiting User Response</span>,
    BOUNDARIES_UPDATE_COMPLETE: <span className="awsui-util-status-info"><CheckCircleIcon fontSize='large'/> Region Ready</span>,
    LIVE: <span className="awsui-util-status-positive"><CheckCircleIcon fontSize='large'/> Live</span>,
    MARKED_FOR_DEPRECATION: <span className="awsui-util-status-info"><AccessTimeIcon fontSize='large'/> Marked For Deprecation</span>,
    UPDATING_TO_LIVE: <span className="awsui-util-status-info"><AccessTimeIcon fontSize='large'/> Updating to Live</span>,
    DEPRECATED: <span className="awsui-util-status-negative"><CheckCircleIcon fontSize='large'/> Deprecated</span>
};