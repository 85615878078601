import React, { FunctionComponent } from 'react';
import { Button, ColumnLayout } from '@amzn/awsui-components-react/polaris';
import { ExperimentStep } from '../enums/CommonTypes';

export interface SummarySectionProps {
    header: string;
    handler?: (_event: CustomEvent) => void;
    editEnabled?: boolean;
    columns: 1 | 2 | 3 | 4 | null | undefined;
    items: ExperimentStep;
}

export const SummarySection: FunctionComponent<SummarySectionProps> = (props: SummarySectionProps) => {
    const isEditButtonDisabled = props.editEnabled ? !props.editEnabled : false;
    
    const displayArray = Object.keys(props.items).map((key) => {
        return { name: props.items[key].displayLabel, value: props.items[key].displayValue };
    });

    return (
        <div className="awsui-util-container">
            <div className="awsui-util-container-header">
                <div className="awsui-util-action-stripe">
                    {props.header}
                    {props.handler && <Button onClick={props.handler} disabled={isEditButtonDisabled}>Edit</Button>}
                </div>
            </div>
            <ColumnLayout columns={props.columns} variant='text-grid'>
                <div data-awsui-column-layout-root="true">
                    {displayArray.map((item: any) => {
                        return (
                            <div key={item.name}>
                                <div className="awsui-util-label"><strong><u>{item.name}</u></strong></div>
                                <div>{item.value}</div>
                            </div>
                        );
                    })}
                </div>
            </ColumnLayout>
        </div>
    );
};
