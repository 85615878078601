import { MetricsTable, TableDimension, TransactionalMetricsRow } from '../lambda-model-types';
import { DataTableDefinition } from '../../../common/DataTable';
import { TransactionalMetricColumnMap } from '../../../interfaces/TransactionalMetrics';

export const convertMetricsTableToDataTable = (metricsTable: MetricsTable): DataTableDefinition[] => {
    const dimensionColumnMap = new Map(Object.entries(metricsTable.schema.dimensionColumnIndex));
    const metricColumnMap = new Map(Object.entries(metricsTable.schema.metricColumnIndex));

    const productGroupIdx = dimensionColumnMap.get(TableDimension.AGGREGATED_DIMENSION)!;
    const metricNameIdx = dimensionColumnMap.get(TableDimension.METRIC_DIMENSION)!;
    const allProductGroups = new Set(metricsTable.rows.map((row) => row.dimensionValues[productGroupIdx]));
    
    const colNames = Object.keys(metricsTable.schema.metricColumnIndex);
    const cols: string[] = new Array(colNames.length);
    colNames.forEach((colName) => cols[metricColumnMap.get(colName)] = (TransactionalMetricColumnMap as any)[colName]);
    const columnNames: string[] = ['Metric', ...cols];

    return Array.from(allProductGroups).map((productGroup) => {
        const productGroupRows: any[][] = metricsTable.rows
            .filter((row: TransactionalMetricsRow) => row.dimensionValues[productGroupIdx] === productGroup)
            .map((row: TransactionalMetricsRow) => [row.dimensionValues[metricNameIdx], ...row.metricValues]);

        return {
            columnNames,
            tableName: productGroup,
            rows: productGroupRows
        };
    });
};